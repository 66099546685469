/*==============================================
  Project:   Massive - Multipurpose Responsive Theme
  Build:     Bootstrap 3+
  Author:    ThemeBucket
 ===============================================*/
/*==============================================
  Table of Contents

  0. tb preloader
  1. import css files
  2. typography
  3. header
  4. top mega menu
  5. menu appear alt
  6. floating menu
  7. nav icon
  8. nav link boxed
  9. nav link border box
  10. nav link border bottom
  11. nav center align
  12. nav bottom position
  13. nav block left align
  14. pre-defined >  margin top | margin bottom
  15. body content
  16. portfolio
  17. portfolio with title
  18. portfolio masonry
  19. Isotope Filtering
  20. portfolio details
  21. portfolio nav
  22. masonry grid
  23. Flex Slider
  24. slider revolution
  25. maintenance
  26. top bar
  27. primary nav
  28. countdown
  29. bs slider
  30. static banner
  31. video banner
  32. custom pagination
  33. Product list
  34. contact us
  35. event page
  36. magazine page
  37. catering page
  38. hotel page
  39. app landing page
  40. Slider Typist
  41. Boxed View
  42. scroll top
 ===============================================*/
/*==============================================
 Pre Loader
 ===============================================*/

.page-banner{
    width: 100%;
}

.page-banner img {
    max-width: 100%;
}

.media-meta i {
    color: lightpink;
}
.login-list {
    padding: 0;
}

.login-list li{
    list-style: none;
    margin-left: 10px;
    line-height: 25px;
}

.login-list li:before {
    color: red;
    position: relative;
    left: -5px;
    top: -3px;
    content: '\f00c';
    font-family: 'FontAwesome';
}
.featured-item {
    display: inline-block;
    width: 100%;
    margin: 5px;
    padding: 10px;
    border: solid 1px;
}

.career-box span.v-date {
    display: block;
    font-size: 12px;
    font-style: italic;
    text-align: right;
    color: #daa97e;
    padding: 10px 0;
}

.btn-fb {
    color: #fff;
    border-color: #3D5A98;
    background-color: #3D5A98;
}

.btn-fb:hover {
    color: #fff;
    background-color: #4d7cca;
}
.btn-gplus {
    color: #fff;
    border-color: #DC4A38;
    background-color: #DC4A38;
}
.btn-gplus:hover {
    color: #fff;
    background-color: #ff5b44;
}
.has-error label {
    color: #a94442;
}
html {
    font-family: 'Open Sans', 'PMingLiU', sans-serif;
}
#tb-preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: #fff;
}

.tb-preloader-wave {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 180px;
    margin: -90px 0 0 -90px;
    background-image: url("/assets/images/loading.gif");
}

/*.tb-preloader-wave:before,
.tb-preloader-wave:after {
    position: absolute;
    display: inline-block;
    width: 60px;
    height: 60px;
    content: "";
    -webkit-animation: preloader-wave 1.6s linear infinite;
            animation: preloader-wave 1.6s linear infinite;
    border-radius: 50%;
    background: #222;
}

.tb-preloader-wave:after {
    -webkit-animation-delay: -.8s;
            animation-delay: -.8s;
}*/

@-webkit-keyframes preloader-wave {
    0% {
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
        opacity: .5;
    }
    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
        opacity: 0;
    }
}

@keyframes preloader-wave {
    0% {
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
        opacity: .5;
    }
    100% {
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
        opacity: 0;
    }
}

/*==============================================
 Base
 ===============================================*/
body {
    color: #323232;
    font-family: 'Open Sans', 'PMingLiU', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5;

    -webkit-font-smoothing: antialiased;
       -moz-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

a:active,
a:focus {
    outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 30px 0;
    color: #333;
    font-weight: normal;
    line-height: 1.5;
}

h1,
h2 {
    letter-spacing: 2px;
}

h3,
h4 {
    letter-spacing: 2px;
}

h5,
h6 {
    letter-spacing: 1px;
}

a {
    text-decoration: none !important;
}

a:hover,
a:focus {
    color: #323232;
}

a,
.btn {
    -webkit-transition: all .3s;
            transition: all .3s;
}

a img,
iframe {
    border: none;
}

p {
    margin: 0 0 30px;
    color: #7e7e7e;
    line-height: 30px;
}

hr {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    border-top: 1px solid #eee;
}

pre {
    display: block;
    margin: 0 0 30px;
    padding: 9.5px;
    word-wrap: break-word;
    word-break: break-all;
    color: #333;
    border: 1px solid #ededed;
    border-radius: 0;
    background-color: #f9f9f9;
    font-size: 13px;
    line-height: 1.42857143;
}

.parallax-window {
    min-height: 400px;
    padding: 150px 0;
    background: transparent;
}

/*==============================================
 Utility
 ===============================================*/
.column-two,
.column-three,
.column-four {
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
}

.column-two {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
}

.column-three {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
}

.column-four {
    -webkit-column-count: 4;
       -moz-column-count: 4;
            column-count: 4;
}

.m-top-0,
.m-top-10,
.m-top-15,
.m-top-20,
.m-top-25,
.m-top-30,
.m-top-35,
.m-top-40,
.m-top-50,
.m-top-80,
.m-top-100,
.m-bot-0,
.m-bot-10,
.m-bot-15,
.m-bot-20,
.m-bot-25,
.m-bot-30,
.m-bot-35,
.m-bot-40,
.m-bot-50,
.m-bot-80,
.m-bot-100 {
    display: inline-block;
}

.m-top-0 {
    margin-top: 0 !important;
}

.m-top-5 {
    margin-top: 5px;
}

.m-top-10 {
    margin-top: 10px;
}

.m-top-15 {
    margin-top: 15px;
}

.m-top-20 {
    margin-top: 20px;
}

.m-top-25 {
    margin-top: 25px;
}

.m-top-30 {
    margin-top: 30px;
}

.m-top-35 {
    margin-top: 35px;
}

.m-top-40 {
    margin-top: 40px;
}

.m-top-50 {
    margin-top: 50px;
}

.m-top-80 {
    margin-top: 80px;
}

.m-top-100 {
    margin-top: 100px;
}

/*margin bottom*/
.m-bot-0 {
    margin-bottom: 0 !important;
}

.m-bot-10 {
    margin-bottom: 10px;
}

.m-bot-15 {
    margin-bottom: 15px;
}

.m-bot-20 {
    margin-bottom: 20px;
}

.m-bot-25 {
    margin-bottom: 25px;
}

.m-bot-30 {
    margin-bottom: 30px;
}

.m-bot-35 {
    margin-bottom: 35px;
}

.m-bot-40 {
    margin-bottom: 40px;
}

.m-bot-50 {
    margin-bottom: 50px;
}

.m-bot-80 {
    margin-bottom: 80px;
}

.m-bot-100 {
    margin-bottom: 100px;
}

.m-left-10 {
    margin-left: 20px;
}

/*padding top*/
.p-top-0 {
    padding-top: 0 !important;
}

.p-top-10 {
    padding-top: 10px;
}

.p-top-15 {
    padding-top: 15px;
}

.p-top-20 {
    padding-top: 20px;
}

.p-top-25 {
    padding-top: 25px;
}

.p-top-30 {
    padding-top: 30px;
}

.p-top-35 {
    padding-top: 35px;
}

.p-top-40 {
    padding-top: 40px;
}

.p-top-50 {
    padding-top: 50px;
}

.p-top-80 {
    padding-top: 80px;
}

.p-top-100 {
    padding-top: 100px;
}

.p-tb-10 {
    padding: 10px 0;
}

.p-tb-20 {
    padding: 20px 0 !important;
}

.p-tb-30 {
    padding: 30px 0;
}

.p-tb-50 {
    padding: 50px 0;
}

.p-tb-100 {
    padding: 100px 0;
}

.p-tb-150 {
    padding: 150px 0;
}

.p-tb-200 {
    padding: 200px 0;
}

.p-top-0 {
    padding-top: 0 !important;
}

.p-bot-0 {
    padding-bottom: 0 !important;
}

.p-bot-100 {
    padding-bottom: 100px !important;
}

.theme-bg-space {
    padding: 10px 20px;
}

.bg-space-lg {
    padding: 40px;
}

.bg-space-m {
    padding: 20px 100px;
}

.inline-block {
    display: inline-block;
    width: 100%;
}

.relative {
    position: relative;
}

.vertical-align {
    position: relative;
}

.ls-20 {
    letter-spacing: 20px !important;
}

.ls-40 {
    letter-spacing: 40px !important;
}

.b-lg-lw {
    font-weight: 100;
}

.txt-xl {
    font-size: 26px !important;
    font-weight: 300;
    line-height: 36px;
}

.txt-sm {
    font-size: 16px !important;
    font-weight: normal;
    line-height: 20px;
}

.height-160 {
    height: 160px !important;
}

.height-227 {
    height: 227px !important;
}

.height-300 {
    height: 300px !important;
}

.height-310 {
    height: 310px !important;
}

.height-361 {
    height: 361px !important;
}

.height-450 {
    height: 450px !important;
}

.height-550 {
    height: 550px !important;
}

.height-600 {
    height: 600px !important;
}

.width-300 {
    width: 300px !important;
}

.dark-bg-alt {
    background: #222;
}

.gray-bg-alt {
    background: #e8e8e8;
}

.opacity-90 {
    opacity: .9;
}

.ls-3 {
    letter-spacing: 3px;
}

.m-r-0 {
    margin-right: 0;
}

.l-weight {
    font-weight: 100 !important;
}

.semi-transparent {
    background: rgba(10, 10, 10, .5) !important;
}

.float-none {
    float: none !important;
}

/*==============================================
 Bootstrap Reset
 ===============================================*/
.container-fluid,
.col-lg-1,
.col-md-1,
.col-sm-1,
.col-xs-1,
.col-lg-2,
.col-md-2,
.col-sm-2,
.col-xs-2,
.col-lg-3,
.col-md-3,
.col-sm-3,
.col-xs-3,
.col-lg-4,
.col-md-4,
.col-sm-4,
.col-xs-4,
.col-lg-5,
.col-md-5,
.col-sm-5,
.col-xs-5,
.col-lg-6,
.col-md-6,
.col-sm-6,
.col-xs-6,
.col-lg-7,
.col-md-7,
.col-sm-7,
.col-xs-7,
.col-lg-8,
.col-md-8,
.col-sm-8,
.col-xs-8,
.col-lg-9,
.col-md-9,
.col-sm-9,
.col-xs-9,
.col-lg-10,
.col-md-10,
.col-sm-10,
.col-xs-10,
.col-lg-11,
.col-md-11,
.col-sm-11,
.col-xs-11,
.col-lg-12,
.col-md-12,
.col-sm-12,
.col-xs-12 {
    padding-right: 30px;
    padding-left: 30px;
}

.btn-default {
    border-color: #e5e5e5;
}

/*==============================================
 Top Bar
 ===============================================*/
.top-bar {
    height: 40px;
    border-bottom: 1px solid #eee;
    line-height: 40px;
}

.top-bar .gray-bg {
    background: #f5f5f5;
}

.top-social-link a {
    display: inline-block;
    margin-right: 10px;
    color: #aaa;
}

.top-social-link a:hover,
.top-link li a:hover {
    color: #222;
}

.top-link {
    float: right;
    margin: 0;
    padding: 0;
    list-style: none;
}

.top-link li {
    display: inline-block;
    margin: 0 5px;
}

.top-link li i,
.top-link li a i {
    margin-right: 10px;
}

.top-link li,
.top-link li a {
    color: #aaa;
}

.top-link.text-uppercase {
    font-size: 12px;
}

.top-bar .col-lg-1,
.top-bar .col-lg-10,
.top-bar .col-lg-11,
.top-bar .col-lg-12,
.top-bar .col-lg-2,
.top-bar .col-lg-3,
.top-bar .col-lg-4,
.top-bar .col-lg-5,
.top-bar .col-lg-6,
.top-bar .col-lg-7,
.top-bar .col-lg-8,
.top-bar .col-lg-9,
.top-bar .col-md-1,
.top-bar .col-md-10,
.top-bar .col-md-11,
.top-bar .col-md-12,
.top-bar .col-md-2,
.top-bar .col-md-3,
.top-bar .col-md-4,
.top-bar .col-md-5,
.top-bar .col-md-6,
.top-bar .col-md-7,
.top-bar .col-md-8,
.top-bar .col-md-9,
.top-bar .col-sm-1,
.top-bar .col-sm-10,
.top-bar .col-sm-11,
.top-bar .col-sm-12,
.top-bar .col-sm-2,
.top-bar .col-sm-3,
.top-bar .col-sm-4,
.top-bar .col-sm-5,
.top-bar .col-sm-6,
.top-bar .col-sm-7,
.top-bar .col-sm-8,
.top-bar .col-sm-9,
.top-bar .col-xs-1,
.top-bar .col-xs-10,
.top-bar .col-xs-11,
.top-bar .col-xs-12,
.top-bar .col-xs-2,
.top-bar .col-xs-3,
.top-bar .col-xs-4,
.top-bar .col-xs-5,
.top-bar .col-xs-6,
.top-bar .col-xs-7,
.top-bar .col-xs-8,
.top-bar .col-xs-9 {
    padding-right: 15px;
    padding-left: 15px;
}

.top-link.divider li:after {
    display: inline-block;
    padding-left: 13px;
    content: "/";
}

.top-link.divider li:last-child:after {
    display: inline-block;
    content: "";
}

/*==============================================
 Header
 ===============================================*/
/*top-ads*/
.top-ads {
    float: right;
    margin-top: 10px;
}

/*==============================================
 Navbar
 ===============================================*/
.menuzord {
    position: relative;
    float: left;
    width: 100%;
    padding: 0;
    letter-spacing: .5px;
    text-transform: uppercase;
    background: none;
}

.logo-brand {
    float: left;
    height: 50px;
    margin-right: 30px;
    color: #666;
    font-size: 20px;
    font-weight: 600;
    line-height: 50px;
}

.logo-brand img {
    position: relative;
    top: -2px;
    display: inline-block;
    max-height: 100%;
    padding: 2px 0;
}

.menuzord-menu {
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

.menuzord-menu.menuzord-right {
    float: right;
}

.menuzord-menu ul.dropdown li a,
.menuzord-menu > li > .megamenu a,
.menuzord-menu > li > .megamenu h5 {
    color: #222;
}

.menuzord-menu > li > .megamenu h5 {
    margin-bottom: 20px;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
}

.menuzord-menu > li > .megamenu .megamenu-row li a {
    display: inline-block;
    width: 100%;
    padding: 8px 10px;
    font-size: 12px;
}

.menuzord-menu a {
    -webkit-transition: color .3s, background .3s;
            transition: color .3s, background .3s;
}

.menuzord-menu.scrollable > .scrollable-fix {
    display: none;
}

.menuzord-menu > li {
    display: inline-block;
    float: left;
    margin-left: 2px;
}

.menuzord-menu > li > a {
    display: inline-block;
    padding: 0 12px;
    text-decoration: none;
    color: #333;
    outline: 0;
    font-size: 16px;
    font-weight: normal;

    -webkit-tap-highlight-color: transparent;
}

.menuzord-menu > li.active > a,
.menuzord-menu > li:hover > a {
    color: #333;
}

.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 190px;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    background: #fff;
}

.menuzord-menu ul.dropdown li ul.dropdown {
    top: -1px;
    left: 100%;
}

.menuzord-menu ul.dropdown li {
    position: relative;
    clear: both;
    width: 100%;
    border: 0;
    font-size: 15px;
}

.menuzord-menu ul.dropdown li a {
    position: relative;
    display: inline-block;
    float: left;
    clear: both;
    width: 100%;
    padding: 10px 25px 10px 22px;
    text-decoration: none;
    color: #222;
    outline: 0;
    background: 0 0;
}

.menuzord-menu ul.dropdown li:hover > a {
    padding-right: 19px;
    color: #333;
}

.menuzord-menu ul.dropdown.dropdown-left {
    left: auto;
}

.menuzord-menu ul.dropdown li ul.dropdown.dropdown-left {
    right: 100%;
    left: auto;
}

.menuzord-menu li .indicator {
    margin-left: 3px;
}

.menuzord-menu li ul.dropdown li .indicator {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 15px;
}

.menuzord-menu > li > .megamenu {
    position: absolute;
    z-index: 1000;
    left: 0;
    display: none;
    width: 100%;
    padding: 20px;
    border-top: solid 1px #f0f0f0;
    background: #fff;
}

.menuzord-menu > li > .megamenu.megamenu-half-width {
    left: auto;
    width: 50%;
}

.menuzord-menu > li > .megamenu.megamenu-quarter-width {
    left: auto;
    width: 25%;
}

.menuzord-menu > li > .megamenu .megamenu-row {
    width: 100%;
    margin-top: 15px;
}

.menuzord-menu > li > .megamenu .megamenu-row:first-child {
    margin-top: 0;
}

.menuzord-menu > li > .megamenu .megamenu-row:after,
.menuzord-menu > li > .megamenu .megamenu-row:before {
    display: table;
    content: "";
    line-height: 0;
}

.menuzord-menu > li > .megamenu .megamenu-row:after {
    clear: both;
}

.menuzord-menu > li > .megamenu .megamenu-row .col1,
.menuzord-menu > li > .megamenu .megamenu-row .col10,
.menuzord-menu > li > .megamenu .megamenu-row .col11,
.menuzord-menu > li > .megamenu .megamenu-row .col12,
.menuzord-menu > li > .megamenu .megamenu-row .col2,
.menuzord-menu > li > .megamenu .megamenu-row .col3,
.menuzord-menu > li > .megamenu .megamenu-row .col4,
.menuzord-menu > li > .megamenu .megamenu-row .col5,
.menuzord-menu > li > .megamenu .megamenu-row .col6,
.menuzord-menu > li > .megamenu .megamenu-row .col7,
.menuzord-menu > li > .megamenu .megamenu-row .col8,
.menuzord-menu > li > .megamenu .megamenu-row .col9 {
    display: block;
    float: left;
    min-height: 20px;
    margin-left: 3%;
}

.menuzord-menu > li > .megamenu .megamenu-row [class*=col]:first-child {
    margin-left: 0;
}

.menuzord-menu > li > .megamenu .megamenu-row .col1 {
    width: 5.583333333333%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col2 {
    width: 14.166666666666%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col3 {
    width: 22.75%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col4 {
    width: 31.333333333333%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col5 {
    width: 39.916666666667%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col6 {
    width: 48.5%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col7 {
    width: 57.083333333333%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col8 {
    width: 65.666666666667%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col9 {
    width: 74.25%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col10 {
    width: 82.833333333334%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col11 {
    width: 91.416666666667%;
}

.menuzord-menu > li > .megamenu .megamenu-row .col12 {
    width: 100%;
}

.menuzord .showhide {
    display: none;
    float: right;
    width: 30px;
    height: 46px;
    padding: 13px 0 0;
    text-decoration: none;
    outline: 0;

    -webkit-tap-highlight-color: transparent;
}

.menuzord .showhide em {
    float: right;
    width: 20px;
    height: 3px;
    margin: 3.5px 0 0;
    background: #777;
}

.menuzord-menu > li > a > .fa {
    float: left;
    margin: 0 5px 0 0;
    color: inherit;
    font-size: 14px;
    line-height: inherit;
}

.menuzord-menu > li.search form {
    float: left;
    padding: 22px 16px 17px;
}

.menuzord-tabs {
    float: left;
    width: 100%;
}

.menuzord-tabs-nav {
    float: left;
    width: 20%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.menuzord-tabs-nav > li > a {
    float: left;
    width: 100%;
    padding: 7px 16px;
    text-decoration: none;
    color: #666;
    border: 1px solid #f0f0f0;
    outline: 0;
    font-size: 13px;
}

.menuzord-tabs-nav li.active a,
.menuzord-tabs-nav li:hover a {
    background: #f0f0f0;
}

.menuzord-tabs-content {
    display: none;
    float: right;
    width: 80%;
    min-height: 30px;
    padding: 20px;
    border: 1px solid #f0f0f0;
    font-size: 13px;
}

.menuzord-tabs-content.active {
    display: block;
}

.menuzord-menu ul.dropdown,
.menuzord-menu ul.dropdown li ul.dropdown {
    border: 1px solid #efefef;
    background: #fff;
}

.menuzord-menu ul.dropdown li:hover > a {
    color: #fff;
}

.menuzord-menu > li > .megamenu {
    border: 1px solid #efefef;
    border-top: none;
    background: #fff;
}

.menuzord-tabs-nav > li > a {
    color: #fff;
}

.navbar-search .form-control {
    border-radius: 0;
}

@media (max-width: 768px) {
    .l-header {
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 100%;
        background: #fff;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .1);
    }
    .menuzord-responsive .menuzord-menu > li.nav-divider {
        display: none;
    }
    .l-navbar_t-light,
    .l-navbar_t-light-trans,
    .l-navbar_t-dark .showhide em,
    .l-navbar_t-semi-trans .showhide em,
    .l-navbar_t-dark-trans .showhide em {
        background: #fff;
    }
    .l-navbar_t-dark,
    .l-navbar_t-semi-trans,
    .l-navbar_t-dark-trans {
        background: #0a0a0a;
    }
}

@media (min-width: 769px) {
    .l-header_overlay {
        position: absolute;
        z-index: 1200;
        top: 0;
        width: 100%;
    }
    .menuzord-menu .megamenu,
    .menuzord-menu .dropdown {
        text-align: left;
        border: 0 !important;
    }
    .menuzord-menu .megamenu,
    .menuzord-menu .dropdown,
    .l-navbar-wrapper_has-sticky .l-navbar,
    .l-navbar_s-left {
        box-shadow: 0 2px 8px rgba(0, 0, 0, .4);
    }
    .logo-brand {
        display: inline-block;
        height: 100px;
    }
    .l-navbar-wrapper_has-sticky .logo-brand {
        display: block;
    }
    .logo-brand,
    .c-nav_s-standard > li > a {
        line-height: 100px;
    }
    .c-nav_s-bg > li > a {
        line-height: 40px;
    }
    .c-nav_s-bg > li.active > a,
    .c-nav_s-bg > li:hover > a {
        color: #fff !important;
    }
    .c-nav_s-outline > li > a {
        border: 1px solid transparent;
        line-height: 38px;
    }
    .c-nav_s-underline > li > a {
        border-bottom: 1px solid transparent;
        line-height: 99px;
    }
    .l-navbar_expand .c-nav_s-bg > li > a,
    .l-navbar_expand .c-nav_s-outline > li > a {
        margin: 30px 0;
    }
    .l-navbar_compact .c-nav_s-bg > li > a,
    .l-navbar_compact .c-nav_s-outline > li > a {
        margin: 10px 0;
    }
    .l-navbar-wrapper_has-sticky .c-nav_s-bg > li > a,
    .l-navbar-wrapper_has-sticky .c-nav_s-outline > li > a {
        margin: 0;
    }
    .l-navbar-wrapper_has-sticky .c-nav_s-outline > li > a {
        line-height: 58px;
    }
    .menuzord-menu .dropdown > li > a,
    .menuzord-menu .megamenu-row li > a {
        line-height: 18px;
    }
    .l-navbar-wrapper,
    .logo-brand,
    .menuzord-menu > li > a {
        -webkit-transition: height .3s, line-height .3s, margin .3s;
                transition: height .3s, line-height .3s, margin .3s;
    }
    .l-navbar_t-light {
        background: #fff;
    }
    .l-navbar_t-dark {
        background: #0a0a0a;
    }
    .l-navbar_t-dark .menuzord-menu > li > a,
    .l-navbar_t-semi-trans .menuzord-menu > li > a,
    .l-navbar_t-dark-trans .menuzord-menu > li > a {
        color: #fff;
    }
    .l-navbar_t-semi-trans {
        background: rgba(10, 10, 10, .5);
    }
    .l-navbar_t-light-trans,
    .l-navbar_t-dark-trans,
    .l-navbar_t-semi-trans {
        -webkit-transition: background-color .3s;
                transition: background-color .3s;
    }
    .l-navbar_compact .logo-brand {
        height: 60px;
    }
    .l-navbar_compact .logo-brand,
    .l-navbar_compact .c-nav_s-standard > li > a {
        line-height: 60px;
    }
    .l-navbar_compact.l-navbar_s-center .logo-brand {
        height: 100px;
    }
    .l-navbar_s-center {
        text-align: center;
    }
    .l-navbar_s-center.l-navbar_t-light {
        border-top-color: #ddd;
    }
    .l-navbar_s-center.l-navbar_t-light-trans .menuzord-menu,
    .l-navbar_s-center.l-navbar_t-dark-trans .menuzord-menu,
    .l-navbar_s-center.l-navbar_t-semi-trans .menuzord-menu {
        border-top-color: rgba(10, 10, 10, .2);
    }
    .l-navbar_s-center.l-navbar_t-dark {
        border-top-color: #222;
    }
    .l-navbar_s-center .logo-brand {
        float: none;
    }
    .l-navbar_s-center .menuzord-menu,
    .l-navbar_s-center .menuzord-menu > li {
        float: none;
        text-align: center;
    }
    .l-navbar_s-center .logo-brand.sticky-fix {
        float: left;
    }
    .l-navbar-wrapper_has-sticky .l-navbar_s-center .menuzord-menu.menuzord-right,
    .l-navbar-wrapper_has-sticky .l-navbar_s-center .menuzord-menu.pull-right {
        float: right;
    }
    .l-navbar_s-floating {
        width: 1170px;
        margin: auto;
        margin-top: 50px;
    }
    .l-navbar-wrapper_has-sticky .l-navbar_s-floating {
        margin-top: 0;
    }
    .l-navbar-wrapper_has-sticky .l-navbar_t-semi-trans,
    .l-navbar-wrapper_has-sticky .l-navbar_t-dark-trans {
        background: #0a0a0a;
    }
    .l-navbar-wrapper_has-sticky .l-navbar_t-light-trans {
        background: #fff;
    }
    .l-navbar-wrapper_has-sticky .logo-brand,
    .l-navbar-wrapper_has-sticky .menuzord-menu > li > a {
        line-height: 60px;
    }
    .l-navbar-wrapper_has-sticky .logo-brand {
        height: 60px;
    }
    .body_has-navbar-left .wrapper {
        margin-left: 260px;
    }
    .body_has-navbar-left .l-header {
        position: fixed;
        z-index: 1000;
        top: 0;
        bottom: 0;
        left: 0;
        width: 260px;
    }
    .body_has-navbar-left .l-navbar_s-left {
        height: 100%;
    }
    .l-navbar_s-left.l-navbar_t-dark {
        background: #333;
    }
    .l-navbar_s-left .container,
    .l-navbar_s-left .container-fluid,
    .l-navbar_s-left .col-lg-1,
    .l-navbar_s-left
    .col-md-1,
    .l-navbar_s-left
    .col-sm-1,
    .l-navbar_s-left
    .col-xs-1,
    .l-navbar_s-left .col-lg-2,
    .l-navbar_s-left
    .col-md-2,
    .l-navbar_s-left
    .col-sm-2,
    .l-navbar_s-left
    .col-xs-2,
    .l-navbar_s-left .col-lg-3,
    .l-navbar_s-left
    .col-md-3,
    .l-navbar_s-left
    .col-sm-3,
    .l-navbar_s-left
    .col-xs-3,
    .l-navbar_s-left .col-lg-4,
    .l-navbar_s-left
    .col-md-4,
    .l-navbar_s-left
    .col-sm-4,
    .l-navbar_s-left
    .col-xs-4,
    .l-navbar_s-left .col-lg-5,
    .l-navbar_s-left
    .col-md-5,
    .l-navbar_s-left
    .col-sm-5,
    .l-navbar_s-left
    .col-xs-5,
    .l-navbar_s-left .col-lg-6,
    .l-navbar_s-left
    .col-md-6,
    .l-navbar_s-left
    .col-sm-6,
    .l-navbar_s-left
    .col-xs-6,
    .l-navbar_s-left .col-lg-7,
    .l-navbar_s-left
    .col-md-7,
    .l-navbar_s-left
    .col-sm-7,
    .l-navbar_s-left
    .col-xs-7,
    .l-navbar_s-left .col-lg-8,
    .l-navbar_s-left
    .col-md-8,
    .l-navbar_s-left
    .col-sm-8,
    .l-navbar_s-left
    .col-xs-8,
    .l-navbar_s-left .col-lg-9,
    .l-navbar_s-left
    .col-md-9,
    .l-navbar_s-left
    .col-sm-9,
    .l-navbar_s-left
    .col-xs-9,
    .l-navbar_s-left .col-lg-10,
    .l-navbar_s-left
    .col-md-10,
    .l-navbar_s-left
    .col-sm-10,
    .l-navbar_s-left
    .col-xs-10,
    .l-navbar_s-left .col-lg-11,
    .l-navbar_s-left
    .col-md-11,
    .l-navbar_s-left
    .col-sm-11,
    .l-navbar_s-left
    .col-xs-11,
    .l-navbar_s-left .col-lg-12,
    .l-navbar_s-left
    .col-md-12,
    .l-navbar_s-left
    .col-sm-12,
    .l-navbar_s-left
    .col-xs-12 {
        width: auto;
        padding: 0;
    }
    .l-navbar_s-left .logo-brand,
    .l-navbar_s-left .menuzord-menu,
    .l-navbar_s-left .menuzord-menu > li {
        float: none;
    }
    .l-navbar_s-left .logo-brand {
        display: block;
        margin: 40px 20px;
        text-align: center;
    }
    .l-navbar_s-left .menuzord-menu > li {
        display: block;
        margin: 0;
    }
    .l-navbar_s-left .menuzord-menu > li > a {
        display: block;
        padding: 0 20px;
    }
    .l-navbar_s-left .menuzord-menu > li > a {
        line-height: 40px;
    }
    .l-navbar_s-left .menuzord-menu > li > .dropdown,
    .l-navbar_s-left .menuzord-menu > li > .megamenu {
        left: 260px !important;
        margin-top: -40px;
    }
    .l-navbar_s-left .menuzord-menu > li > .megamenu {
        width: 800px;
        padding: 20px;
    }
    .l-navbar_s-left .menuzord-menu > li.nav-divider {
        display: none;
    }
    .l-navbar_s-left .menuzord-menu > li > .navbar-search {
        width: 450px;
    }
    .nav-divider a {
        cursor: text;
    }
    .l-navbar_bottom {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
}

/*==============================================
 Nav: Primary
 ===============================================*/
#primary_nav_wrap ul {
    position: relative;
    z-index: 1200;
    float: left;
    margin: 0;
    padding: 0;
    list-style: none;
}

#primary_nav_wrap ul a {
    display: block;
    padding: 0 15px;
    text-decoration: none;
    color: #8b8b8b;
    font-size: 12px;
    line-height: 40px;
}

#primary_nav_wrap ul li {
    position: relative;
    float: left;
    margin: 0;
    padding: 0;
}

#primary_nav_wrap ul li.current-menu-item {
    background: #ddd;
}

#primary_nav_wrap ul li:hover {
    background: rgba(255, 255, 255, .06);
}

#primary_nav_wrap ul ul {
    position: absolute;
    top: 100%;
    left: 15px;
    display: none;
    /*border: 1px solid #eee;*/
    padding: 0;
    background: #222;
}

#primary_nav_wrap ul ul li {
    float: none;
    min-width: 150px;
}

#primary_nav_wrap ul ul a {
    padding: 10px 15px;
    color: #b0b0b0;
    line-height: 120%;
}

#primary_nav_wrap ul ul ul {
    top: 0;
    left: 100%;
}

#primary_nav_wrap ul li:hover > ul {
    display: block;
}

/*==============================================
 Nav: Push
 ===============================================*/
.navigation-is-open main {
    -webkit-transform: translateX(100%);
        -ms-transform: translateX(100%);
            transform: translateX(100%);
}

.cd-nav-trigger {
    position: fixed;
    z-index: 1300;
    top: 20px;
    right: 2%;
    /* image replacement */
    overflow: hidden;
    width: 40px;
    height: 40px;
    -webkit-transition: -webkit-transform .5s;
            transition: -webkit-transform .5s;
            transition:         transform .5s;
            transition:         transform .5s, -webkit-transform .5s;
    white-space: nowrap;
    text-indent: 100%;
    border-radius: 0;
    background-color: rgba(0, 0, 0, .9);
}

.cd-nav-trigger .cd-nav-icon {
    /* icon created in CSS */
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    width: 20px;
    height: 2px;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    background-color: #fff;
}

.cd-nav-trigger .cd-nav-icon::before,
.cd-nav-trigger .cd-nav-icon:after {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    /* upper and lower lines of the menu icon */
    content: "";
    -webkit-transition: width .5s, top .3s, -webkit-transform .5s;
            transition: width .5s, top .3s, -webkit-transform .5s;
            transition: transform .5s, width .5s, top .3s;
            transition: transform .5s, width .5s, top .3s, -webkit-transform .5s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    background-color: inherit;

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.cd-nav-trigger .cd-nav-icon::before {
    -webkit-transform: translateY(-6px);
        -ms-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-transform-origin: right top;
        -ms-transform-origin: right top;
            transform-origin: right top;
}

.cd-nav-trigger .cd-nav-icon::after {
    -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
            transform: translateY(6px);
    -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
            transform-origin: right bottom;
}

.no-touch .cd-nav-trigger:hover .cd-nav-icon::after {
    top: 2px;
}

.no-touch .cd-nav-trigger:hover .cd-nav-icon::before {
    top: -2px;
}

.cd-nav-trigger svg {
    position: absolute;
    top: 0;
    left: 0;
}

.cd-nav-trigger circle {
    -webkit-transition: stroke-dashoffset .4s 0s;
            transition: stroke-dashoffset .4s 0s;
}

.navigation-is-open .cd-nav-trigger {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
    /* animate arrow --> from hamburger to arrow */
    width: 50%;
    -webkit-transition: width .5s, -webkit-transform .5s;
            transition: width .5s, -webkit-transform .5s;
            transition: transform .5s, width .5s;
            transition: transform .5s, width .5s, -webkit-transform .5s;
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after,
.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
    top: 0;
}

.navigation-is-open .cd-nav-trigger circle {
    -webkit-transition: stroke-dashoffset .4s .3s;
            transition: stroke-dashoffset .4s .3s;

    stroke-dashoffset: 0;
}

@media only screen and (min-width: 1170px) {
    .cd-nav-trigger {
        top: 40px;
    }
}

.cd-nav {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    -webkit-transition: visibility 0s .7s, opacity 0s .7s;
            transition: visibility 0s .7s, opacity 0s .7s;
    opacity: 0;
    background-color: rgba(0, 0, 0, .9);
}

.cd-nav .cd-navigation-wrapper {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* all navigation content */
    height: 100%;
    padding: 40px 5% 40px calc(5% + 80px);
    -webkit-transition: -webkit-transform .7s;
            transition: -webkit-transform .7s;
            transition:         transform .7s;
            transition:         transform .7s, -webkit-transform .7s;
    -webkit-transition-timing-function: cubic-bezier(.86, .01, .77, .78);
            transition-timing-function: cubic-bezier(.86, .01, .77, .78);
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateZ(0);
            transform: translateX(-50%);

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.navigation-is-open .cd-nav {
    visibility: visible;
    -webkit-transition: visibility 0s 0s, opacity 0s 0s;
            transition: visibility 0s 0s, opacity 0s 0s;
    opacity: 1;
}

.navigation-is-open .cd-nav .cd-navigation-wrapper {
    -webkit-transition: -webkit-transform .5s;
            transition: -webkit-transform .5s;
            transition:         transform .5s;
            transition:         transform .5s, -webkit-transform .5s;
    -webkit-transition-timing-function: cubic-bezier(.82, .01, .77, .78);
            transition-timing-function: cubic-bezier(.82, .01, .77, .78);
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

.cd-nav h2 {
    position: relative;
    margin-bottom: 1.7em;
    text-transform: uppercase;
    color: #e8e8e8;
    font-size: 1.3rem;
    font-weight: 800;
}

.cd-nav h2::after {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 60px;
    height: 1px;
    /* bottom separation line */
    content: "";
    background-color: currentColor;
}

.cd-nav .cd-primary-nav {
    margin-top: 60px;
    padding: 0;
    list-style: none;
}

.cd-nav .cd-primary-nav li {
    margin: 1.6em 0;
}

.cd-nav .cd-primary-nav a {
    display: inline-block;
    color: rgba(255, 255, 255, .7);
    /*font-family: "Merriweather", serif;*/
    font-size: 2.4rem;
}

.cd-nav .cd-contact-info {
    margin-top: 80px;
    padding: 0;
    list-style: none;
}

.cd-nav .cd-contact-info li {
    /*font-family: "Merriweather", serif;*/
    margin-bottom: 1.5em;
    color: rgba(255, 255, 255, .6);
    line-height: 1.2;
}

.cd-nav .cd-contact-info a {
    color: #fff;
}

.cd-nav .cd-contact-info span {
    display: block;
}

.cd-nav .cd-contact-info li,
.cd-nav .cd-contact-info a,
.cd-nav .cd-contact-info span {
    font-size: 1.6rem;
}

@media only screen and (min-width: 1170px) {
    .cd-nav .cd-navigation-wrapper {
        padding: 62px 20%;
    }
    .cd-nav .cd-navigation-wrapper::after {
        display: table;
        clear: both;
        content: "";
    }
    .cd-nav .cd-half-block {
        float: left;
        width: 50%;
    }
    .cd-nav .cd-primary-nav {
        margin-top: 0;
    }
    .cd-nav h2 {
        margin-bottom: 5.6em;
        font-size: 1.5rem;
    }
    .cd-nav .cd-primary-nav li {
        margin: 1em 0;
    }
    .cd-nav .cd-primary-nav a {
        font-size: 2.5rem;
        font-weight: 300;
    }
    .cd-nav .cd-contact-info {
        margin-top: 120px;
        text-align: right;
    }
    .cd-nav .cd-contact-info li {
        margin-bottom: 2.4em;
    }
    .cd-nav .cd-contact-info li,
    .cd-nav .cd-contact-info a,
    .cd-nav .cd-contact-info span {
        font-size: 1.6rem;
        line-height: 25px;
    }
}

.no-js main {
    overflow: visible;
    height: auto;
}

.no-js .cd-nav {
    position: static;
    visibility: visible;
}

.no-js .cd-nav .cd-navigation-wrapper {
    overflow: visible;
    height: auto;
    padding: 100px 5%;
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
}

/*==============================================
 Nav: Dotted
 ===============================================*/
.cd-img-replace {
    /* replace text with a background-image */
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
}

/* No Touch devices */
.no-touch #cd-vertical-nav {
    position: fixed;
    z-index: 300;
    top: 50%;
    right: 40px;
    bottom: auto;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.no-touch #cd-vertical-nav li {
    list-style: none;
    text-align: right;
}

.no-touch #cd-vertical-nav a {
    display: inline-block;
    /* prevent weird movements on hover when you use a CSS3 transformation - webkit browsers */

    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}

.no-touch #cd-vertical-nav a:after {
    display: table;
    clear: both;
    content: "";
}

.no-touch #cd-vertical-nav a span {
    display: inline-block;
    float: right;
    -webkit-transform: scale(.6);
        -ms-transform: scale(.6);
            transform: scale(.6);
}

.no-touch #cd-vertical-nav a:hover span,
.no-touch #cd-vertical-nav li.active span {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.no-touch #cd-vertical-nav a:hover .cd-label {
    opacity: 1;
}

.no-touch #cd-vertical-nav a.is-selected .cd-dot {
    background-color: white;
}

.no-touch #cd-vertical-nav .cd-dot {
    position: relative;
    /* we set a top value in order to align the dot with the label. If you change label's font, you may need to change this top value*/
    top: 11px;
    width: 12px;
    height: 12px;
    -webkit-transition: background-color .5s, -webkit-transform .2s;
            transition: background-color .5s, -webkit-transform .2s;
            transition: transform .2s, background-color .5s;
            transition: transform .2s, background-color .5s, -webkit-transform .2s;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    border-radius: 50%;
    background-color: #ababab;
}

.no-touch #cd-vertical-nav .cd-label {
    position: relative;
    margin-right: 10px;
    padding: .4em .5em;
    -webkit-transition: opacity .2s, -webkit-transform .2s;
            transition: opacity .2s, -webkit-transform .2s;
            transition: transform .2s, opacity .2s;
            transition: transform .2s, opacity .2s, -webkit-transform .2s;
    -webkit-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
    color: #7e7e7e;
    font-size: 14px;
}

/* Touch devices */
.touch #cd-vertical-nav {
    position: fixed;
    z-index: 1;
    right: 5%;
    bottom: 30px;
    overflow-y: scroll;
    width: 90%;
    max-width: 400px;
    max-height: 90%;
    -webkit-transition-duration: .2s;
            transition-duration: .2s;
    -webkit-transition-property: -webkit-transform;
            transition-property: -webkit-transform;
            transition-property:         transform;
            transition-property:         transform, -webkit-transform;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: right bottom;
        -ms-transform-origin: right bottom;
            transform-origin: right bottom;
    border-radius: .25em;
    background-color: rgba(255, 255, 255, .9);
}

.touch #cd-vertical-nav a {
    display: block;
    padding: 1em;
    border-bottom: 1px solid rgba(62, 57, 71, .1);
}

.touch #cd-vertical-nav a span:first-child {
    display: none;
}

.touch #cd-vertical-nav a.is-selected span:last-child {
    color: #7e7e7e;
}

.touch #cd-vertical-nav.open {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
}

.touch #cd-vertical-nav.open + .cd-nav-trigger {
    background-color: transparent;
}

.touch #cd-vertical-nav.open + .cd-nav-trigger span {
    background-color: rgba(62, 57, 71, 0);
}

.touch #cd-vertical-nav.open + .cd-nav-trigger span::before,
.touch #cd-vertical-nav.open + .cd-nav-trigger span::after {
    left: -8px;
    width: 20px;
    height: 3px;
    border-radius: 0;
    background-color: #3e3947;
}

.touch #cd-vertical-nav.open + .cd-nav-trigger span::before {
    top: 1px;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
}

.touch #cd-vertical-nav.open + .cd-nav-trigger span::after {
    bottom: 0;
    -webkit-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
            transform: rotate(135deg);
}

.touch #cd-vertical-nav li:last-child a {
    border-bottom: none;
}

@media only screen and (min-width: 768px) {
    .touch .cd-nav-trigger,
    .touch #cd-vertical-nav {
        bottom: 40px;
    }
}

/*==============================================
 Nav: Side
 ===============================================*/
/*------------------------------------------------------------------
    left side nav fixed
-------------------------------------------------------------------*/
body.left-nav-fixed {
    overflow-x: hidden;
}

.left-nav-fixed .wrapper {
    margin-left: 260px;
}

.left-nav-fixed .logo-area {
    display: inline-block;
    width: 100%;
    padding: 40px 20px;
}

.left-nav-fixed .logo-brand {
    float: none;
    margin: 0;
}

.left-nav-fixed #header {
    position: fixed;
    z-index: 300;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    border-right: 1px solid #eee;
    background-color: #fff;
}

.border-less {
    border: none !important;
}

.left-nav-fixed header.header-full-width .container,
.left-nav-fixed header .container {
    width: 100%;
    padding: 0;
}

.left-nav-fixed .container {
    width: auto;
    padding: 0 30px;
}

.left-nav-fixed .menuzord-menu > li > a,
.left-nav-fixed header.sticky .menuzord-menu > li > a {
    padding: 0;
}

.left-nav-fixed .menuzord-menu li,
.left-nav-fixed .menuzord-menu li a {
    width: 100%;
}

.left-nav-fixed .menuzord-menu > li > a {
    padding: 0 20px;
    line-height: 40px;
}

.left-nav-fixed .menuzord-menu li ul {
    margin-top: -40px;
    margin-left: 258px;
}

.left-nav-fixed .menuzord-menu li .megamenu-row ul {
    margin-top: 0;
    margin-left: 0;
}

.left-nav-fixed .menuzord-menu > li > .megamenu {
    position: absolute;
    z-index: 99;
    left: 260px;
    display: none;
    width: 800px;
    margin-top: -40px;
    padding: 20px;
    border-top: none;
    background: none;
    background: #fff;
}

.left-nav-fixed header .light-header,
.left-nav-fixed header.sticky .light-header,
.left-nav-fixed #header.transparent-header.sticky .light-header,
.left-nav-fixed .light-nav {
    background: none !important;
}

.left-nav-fixed header.sticky .header-sticky {
    box-shadow: none;
}

.left-nav-fixed header.sticky .header-sticky,
.left-nav-fixed header.transparent-header {
    position: static;
    width: 100%;
}

.left-nav-fixed .menuzord-menu > li > a,
.left-nav-fixed header.sticky .menuzord-menu > li > a,
.left-nav-fixed .logo-brand,
header.sticky .logo-brand,
.left-nav-fixed header li.nav-icon i,
.left-nav-fixed header.sticky li.nav-icon i {
    -webkit-transition: none;
            transition: none;
}

.left-nav-fixed .menuzord-menu li .indicator {
    float: right;
    margin-left: 3px;
}

.side-social-link {
    display: inline-block;
    margin-top: 50px;
    padding: 0 20px;
    text-align: center;
}

.side-social-link a {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin: 0 2px;
    text-align: center;
    color: #bababa;
    border: 1px solid #bababa;
    border-radius: 50%;
    line-height: 35px;
}

.side-social-link a:hover {
    color: #fff;
}

.left-nav-fixed .menuzord-menu > li > a {
    color: #222;
}

/*dark nav*/
.left-nav-fixed.dark-side-nav #header {
    border-right: none;
    background-color: #333;
}

.left-nav-fixed.dark-side-nav .menuzord-menu > li > a {
    color: #fff;
}

/*nav toggle*/
.side-nav-toggle {
    position: absolute;
    top: 15px;
    right: -50px;
    padding: 5px 10px;
    color: #222;
    background-color: #fff;
}

.side-nav-close .wrapper {
    margin-left: 0 !important;
}

.side-nav-close #header {
    left: -260px !important;
}

.side-nav-open .wrapper {
    margin-left: 260px;
}

.side-nav-open #header {
    left: 0;
}

/*side-push-nav*/
.side-push-nav .container {
    width: 1170px;
}

.side-push-nav .wrapper,
.side-push-nav #header {
    -webkit-transition: all .3s;
            transition: all .3s;
}

/*responsive issue fix*/
body {
    overflow-x: hidden;
}

.body_has-navbar-left .container {
    max-width: 1170px;
}

@media (min-width: 768px) {
    .body_has-navbar-left .container {
        max-width: 505px;
    }
}

@media (min-width: 1012px) {
    .body_has-navbar-left .container {
        max-width: 750px;
    }
}

@media (min-width: 1230px) {
    .body_has-navbar-left .container {
        max-width: 970px;
    }
}

@media (min-width: 1430px) {
    .body_has-navbar-left .container {
        max-width: 1170px;
    }
}

@media (min-width: 768px) {
    .body_has-navbar-left .portfolio.col-3 .portfolio-item {
        width: 100%;
    }
}

@media (min-width: 1012px) {
    .body_has-navbar-left .portfolio.col-3 .portfolio-item {
        width: 50%;
    }
}

@media (min-width: 1230px) {
    .body_has-navbar-left .portfolio.col-3 .portfolio-item {
        width: 50%;
    }
}

@media (min-width: 1430px) {
    .body_has-navbar-left .portfolio.col-3 .portfolio-item {
        width: 33.33333333%;
    }
}

/*==============================================
 Breadcrumb
 ===============================================*/
.breadcrumb li {
    text-transform: uppercase;
}

.breadcrumb li > a {
    color: #7e7e7e;
}

.breadcrumb li > a:hover {
    color: #222;
}

.breadcrumb > .active {
    color: #222;
}

/*==============================================
 Body
 ===============================================*/
.body-content {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.parallax {
    height: 100vh;
    background-image: url("/img/demo.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-size: cover;
}

.parallax-inner {
    padding: 150px 0;
    background-image: url("/img/demo.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-size: cover;
}

.banner-state {
    height: 100vh;
    background-image: url("/img/banner/menu_banner.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-size: cover;
}

.full-banner {
    position: relative;
    background-image: url("/img/banner/creative-banner.jpg");
    background-position: 50% 50%;
    background-size: cover;
}

.page-content {
    display: inline-block;
    width: 100%;
    padding: 50px 0;
}

.page-content-sm {
    display: inline-block;
    width: 100%;
    padding: 50px 0;
}

.full-content {
    display: inline-block;
    width: 100%;
    padding: 0 0 !important;
}

.overlay-dark {
    display: inherit;
    background: rgba(0, 0, 0, .5);
}

.overlay-light {
    display: inherit;
    background: rgba(255, 255, 255, .5);
}

.massive-list {
    padding-left: 15px;
}

.massive-list li {
    margin-bottom: 10px;
}

/*==============================================
 Isotope
 ===============================================*/
.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    z-index: 1;
    pointer-events: none;
}

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: .8s;
            transition-duration: .8s;
}

.isotope {
    -webkit-transition-property: height, width;
            transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: opacity, -webkit-transform;
            transition-property: opacity, -webkit-transform;
            transition-property: transform, opacity;
            transition-property: transform, opacity, -webkit-transform;
}

/*disabling Isotope CSS3 transitions */
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
}

/* disable CSS transitions for containers with infinite scrolling*/
.isotope.infinite-scrolling {
    -webkit-transition: none;
            transition: none;
}

/*==============================================
 Portfolio
 ===============================================*/
.portfolio {
    margin: 50px 0;
}

.portfolio .portfolio-item {
    float: left;
}

.portfolio-item .thumb {
    position: relative;
}

.portfolio .portfolio-item .thumb img {
    display: block;
    width: 100%;
    height: auto;
}

.portfolio-hover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    -webkit-transition: all .3s;
            transition: all .3s;
    opacity: 0;
    border: 20px solid rgba(0, 0, 0, .1);
    background-color: rgba(255, 255, 255, .9);
}

.portfolio-item:hover .portfolio-hover,
.portfolio-item:hover .portfolio-hover .portfolio-description,
.portfolio-item:hover .portfolio-hover .action-btn {
    opacity: 1;
}

.portfolio-item:hover .portfolio-hover .portfolio-description {
    bottom: 45%;
    margin-bottom: -90px;
}

.portfolio-item:hover .portfolio-hover .action-btn {
    top: 40%;
    margin-top: -55px;
}

.portfolio-hover .portfolio-description {
    position: absolute;
    bottom: 0;
    width: 100%;
    -webkit-transition: all .3s;
            transition: all .3s;
    -webkit-transform: translateY(-55%);
        -ms-transform: translateY(-55%);
            transform: translateY(-55%);
    opacity: 0;
}

.portfolio-hover .action-btn {
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-transition: all .3s;
            transition: all .3s;
    -webkit-transform: translateY(45%);
        -ms-transform: translateY(45%);
            transform: translateY(45%);
    text-align: center;
    opacity: 0;
    font-size: 35px;
}

.portfolio-description,
.portfolio-description h4 a,
.portfolio-hover .action-btn a {
    text-align: center;
    color: #222;
}

.portfolio-description h4 {
    margin-bottom: 5px;
    letter-spacing: 4px;
    text-transform: uppercase;
    font-size: 14px;
}

.portfolio-description p {
    margin-bottom: 0;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #757575;
    font-size: 12px;
    font-weight: normal;
}

.portfolio-description a,
.portfolio-title a {
    color: #757575;
}

/*gutter*/
.portfolio.gutter .portfolio-item {
    padding: 10px;
}

/*portfolio 2 grid*/
.portfolio.col-2 .portfolio-item {
    width: 49.99%;
}

/*portfolio 3 grid*/
.portfolio.col-3 .portfolio-item {
    width: 33.3333333%;
}

/*portfolio 4 grid*/
.portfolio.col-4 .portfolio-item {
    width: 24.98%;
}

/*portfolio 5 grid*/
.portfolio.col-5 .portfolio-item {
    width: 19.99%;
}

/*portfolio 6 grid*/
.portfolio.col-6 .portfolio-item {
    width: 16.666666667%;
}

.portfolio-filter,
.category-filter {
    margin: 0;
    padding: 0;
    list-style: none;
}

.portfolio-filter li,
.category-filter li {
    display: inline-block;
}

.portfolio-filter li a,
.category-filter li a {
    margin: 0 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #a9a8a8;
    font-size: 18px;
    line-height: 36px;
}

.portfolio-filter li a:hover,
.portfolio-filter li.active a,
.category-filter li a:hover {
    color: #222;
}

/* ----------------------------------------------------------------
	portfolio with title
-----------------------------------------------------------------*/
.portfolio-with-title .portfolio-item:hover .portfolio-hover .action-btn {
    top: 50%;
    margin-top: -45px;
}

.portfolio-with-title .portfolio-title {
    padding: 15px 0;
}

.portfolio-with-title .portfolio-title h4,
.portfolio-with-title .portfolio-title h4 a {
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #222;
    font-size: 14px;
}

.portfolio-with-title .portfolio-title h3,
.portfolio-with-title .portfolio-title h3 a {
    margin: 0 0 10px 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #222;
    font-size: 18px;
}

.portfolio-with-title .portfolio-title p {
    margin: 0;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #7e7e7e;
    font-size: 12px;
    /*font-family: 'Abel', sans-serif;*/
    font-weight: normal;
}

.portfolio-with-title.portfolio-masonry .portfolio-title {
    padding: 15px;
}

.portfolio-with-title.portfolio-masonry.gutter .portfolio-title {
    padding: 15px 0;
}

/* ----------------------------------------------------------------
	portfolio masonry
-----------------------------------------------------------------*/
/* clear fix */
.portfolio-masonry:after {
    display: block;
    clear: both;
    content: "";
}

/* ---- .grid-item ---- */
.portfolio-masonry .portfolio-item {
    float: left;
}

.portfolio-masonry .portfolio-item-width2 {
    width: 200px;
}

.portfolio-masonry .portfolio-item-height2 {
    height: 200px;
}

.portfolio-nav-row {
    position: relative;
    padding: 40px 0;
}

.portfolio-nav {
    position: absolute;
    top: 50%;
    right: 10px;
    left: auto;
    max-width: 300px;
    margin-top: -12px;
}

.portfolio-nav.left {
    position: absolute;
    top: 50%;
    right: auto;
    left: 0;
    max-width: 300px;
    margin-top: -12px;
}

.portfolio-nav a {
    display: block;
    float: left;
    margin-left: 20px;
    text-align: center;
    color: #aeaeae;
}

.portfolio-nav.left a {
    margin-right: 20px;
    margin-left: 0;
}

.portfolio-nav a:hover,
.portfolio-nav a:hover span {
    -webkit-transition: all .3s;
            transition: all .3s;
    color: #323232;
}

.portfolio-nav a span,
.portfolio-nav a span i {
    display: inline-block;
    margin-top: 0;
    -webkit-transition: all .3s;
            transition: all .3s;
}

.portfolio-gap-less .portfolio {
    margin: 0;
}

/*==============================================
 Banner: Flex
 ===============================================*/
.slides {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
    border: none;
    outline: none;
}

.flex-control-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
}

.flex-direction-nav {
    display: none;
}

.flexslider {
    position: relative;
    margin: 0;
    padding: 0;
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
            transition: all 1s ease;
}

.flex-control-nav {
    position: absolute;
    z-index: 10;
    right: 15px;
    bottom: 15px;
    margin: 0;
    text-align: center;
}

.flex-control-nav li {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    margin: 0 3px;
}

.flex-control-nav li a {
    display: block;
    width: 10px;
    height: 10px;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
         -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
    text-indent: -9999px;
    border: 1px solid #fff;
    border-radius: 50%;
}

.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
    background-color: #fff;
}

.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flex-direction-nav {
    *height: 0;
}

.flex-direction-nav a {
    position: absolute;
    z-index: 200;
    top: 50%;
    display: block;
    overflow: hidden;
    width: 40px;
    height: 40px;
    margin: -40px 0 0;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
    text-decoration: none;
    color: rgba(0, 0, 0, .8);
    background-color: rgba(0, 0, 0, .5);
    line-height: 40px;
}

.flex-direction-nav a:hover {
    background-color: rgba(0, 0, 0, .7);
}

.flex-direction-nav a:before {
    display: block;
    width: 40px;
    content: "\f104";
    text-align: center;
    color: #fff;
    font-family: "fontawesome";
    font-size: 16px;
}

.flex-direction-nav a.flex-next:before {
    content: "\f105";
}

.flex-direction-nav .flex-prev {
    left: 0;
}

.flex-direction-nav .flex-next {
    right: 0;
    text-align: right;
}

.post-slider-thumb .flex-control-thumbs {
    position: relative;
    z-index: 10;
    top: 10px;
    right: auto;
    left: auto;
    margin: 0 auto;
    text-align: left;
}

.post-slider-thumb .flex-control-thumbs li {
    display: inline-block;
    float: none;
    width: 80px;
    height: auto;
    margin: 0 3px 0 0;
    cursor: pointer;
}

.post-slider .flex-direction-nav,
.post-slider-thumb .flex-direction-nav {
    display: block;
}

.post-slider .flex-direction-nav .flex-prev,
.post-slider .flex-direction-nav .flex-next,
.post-slider-thumb .flex-direction-nav .flex-prev,
.post-slider-thumb .flex-direction-nav .flex-next {
    opacity: 0;
}

.post-slider:hover .flex-direction-nav .flex-prev,
.post-slider:hover .flex-direction-nav .flex-next,
.post-slider-thumb:hover .flex-direction-nav .flex-prev,
.post-slider-thumb:hover .flex-direction-nav .flex-next {
    opacity: 1;
}

.post-list-aside .post-img img {
    border: 2px solid transparent;
}

.post-list-aside .post-img img.flex-active {
    border: 2px solid rgba(0, 0, 0, .8);
}

.post-slider .flex-control-thumbs {
    display: none;
}

@media screen and (max-width: 860px) {
    .flex-direction-nav .flex-prev,
    .post-slider .flex-direction-nav .flex-prev,
    .post-slider-thumb .flex-direction-nav .flex-prev {
        left: 0;
        opacity: 1;
    }
    .flex-direction-nav .flex-next,
    .post-slider .flex-direction-nav .flex-next,
    .post-slider-thumb .flex-direction-nav .flex-next {
        right: 0;
        opacity: 1;
    }
}

/*==============================================
 Banner: Revolution
 ===============================================*/
.tp-banner-container {
    position: relative;
    width: 100%;
    padding: 0;
}

.tp-banner {
    position: relative;
    width: 100%;
}

.tp-banner > ul {
    padding: 0;
    list-style: none;
}

.tp-banner-fullscreen-container {
    position: relative;
    width: 100%;
    padding: 0;
}

.rev-heading {
    letter-spacing: 5px;
    text-transform: uppercase;
    color: #333;
    /*font: normal 60px/1.5 'Abel', sans-serif;*/
    font: normal 60px/1.5 "Source Sans Pro", sans-serif;
    font-weight: 300;
}

.rev-subheading {
    letter-spacing: 6px;
    /*font-family: 'Abel', sans-serif;*/
    text-transform: uppercase;
    color: #555;
    font-size: 20px;
}

.rev-desc {
    color: #454545;
    font-size: 16px;
    line-height: 26px;
}

.rev-white {
    color: #fff;
}

/*==============================================
 Banner: Bootstrap
 ===============================================*/
.bs-hero.full-screen .carousel-inner .item img {
    width: 100%;
    /*height: 100vh;*/
    height: auto;
}

.bs-hero.full-screen .carousel-inner .item .bs-info {
    position: absolute;
    top: 40%;
    left: 12%;
}

.bs-hero.full-screen .carousel-inner .item .bs-info-center {
    position: absolute;
    top: 30%;
    left: 50%;
    width: 1000px;
    margin-left: -500px;
    text-align: center;
}

.bs-hero.full-screen .carousel-inner .item .bs-info-right {
    position: absolute;
    /*width: 1170px;*/
    top: 35%;
    right: 12%;
    text-align: right;
}

.bs-hero.full-screen .carousel-inner .item .bs-info h1 {
    text-transform: uppercase;
}

.bs-hero.full-screen .carousel-indicators {
    z-index: 1200;
}

.bs-hero.full-screen .carousel-inner .item .bs-info h1 span {
    font-size: 18px;
}

.bs-hero.full-screen .carousel.slide .carousel-control {
    width: 30%;
    margin-top: 180px;
    margin-bottom: 120px;
}

/*==============================================
 Banner: Static
 ===============================================*/
.vertical-align .container-mid {
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.light-box,
.dark-box {
    padding: 70px 50px;
}

.light-box {
    outline: 5px solid rgba(255, 255, 255, .5);
    outline-offset: 5px;
    background: rgba(255, 255, 255, .5);
}

.dark-box {
    outline: 5px solid rgba(0, 0, 0, .5);
    outline-offset: 5px;
    background: rgba(0, 0, 0, .5);
}

.banner-box {
    max-width: 650px;
    margin: 0 auto;
}

.banner-box h1,
.banner-box h3,
.banner-title h1,
.banner-title h3,
.banner-box h2,
.banner-box h2,
.banner-title h2,
.banner-title h2 {
    margin-bottom: 0;
}

.banner-box h1,
.banner-title h1 {
    letter-spacing: 5px;
    font-size: 60px;
    font-weight: 300;
    line-height: normal;
}

.banner-box h3,
.banner-title h3 {
    letter-spacing: 6px;
    font-size: 20px;
}

.banner-title.light-txt h1,
.banner-title.light-txt h2,
.banner-title.light-txt h3 {
    color: #fff;
}

.b-lg-txt {
    font-size: 100px !important;
    font-weight: 300;
}

.hero-text {
    position: relative;
    z-index: 6000;
}

.hero-text h1 {
    margin-bottom: 10px;
}

.hero-text a {
    margin: 10px 5px;
}

.banner-scroll a {
    position: absolute;
    z-index: 10;
    bottom: 20px;
    left: 50%;
    margin-left: -11px;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
}

.banner-scroll a:before {
    display: block;
    width: 25px;
    height: 35px;
    margin-bottom: 5px;
    margin-left: 5px;
    content: "";
    border: 2px solid #fff;
    border-radius: 15px;
}

.banner-scroll .scroll-down {
    position: absolute;
    z-index: 2;
    top: 8px;
    left: 50%;
    display: block;
    width: 2px;
    height: 8px;
    margin-left: -3px;
    -webkit-animation: 3s arrow infinite ease;
            animation: 3s arrow infinite ease;
    background: #fff;
}

/*==============================================
 Banner: Video
 ===============================================*/
.video-banner {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.video-banner.overlay-background .container {
    position: absolute;
    z-index: 300;
}

.overlay-background {
    position: relative;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 0;
    background-size: cover;
}

.overlay-background .container {
    position: relative;
    z-index: 300;
}

.overlay-block:hover {
    opacity: .6;
}

.overlay-block {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: all .5s ease;
    background-color: #d9a97b;
}

.overlay-block a {
    display: block;
    position: absolute;
    z-index: 100;
    width: 80px;
    height: 80px;
    border: solid 5px #fff;
    text-align: center;
    color: #fff !important;
    line-height: 70px;
    font-weight: 700;
    font-size: 50px;
    border-radius: 50%;
    left: 0;
    right: 0;
    bottom: 50%;
    margin: 0 auto -30px auto;
    padding-left: 10px;
}

.overlay-block-light {
    position: absolute;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .45);
}

/*==============================================
 Video Parallax
 ===============================================*/
.video-parallax {
    position: relative;
    overflow: hidden;
}

.video-parallax .container {
    position: absolute;
    z-index: 100;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -80px;
    color: #fff;
}

.video-parallax .container i {
    display: block;
    margin-bottom: 30px;
    font-size: 50px;
}

.video-parallax .container + .video-wrap {
    z-index: 1;
}

.video-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.video-overlay,
.banner-overaly {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);

    -webkit-backface-visibility: hidden;
}

/*==============================================
 Countdown
 ===============================================*/
.count-down,
.count-down-alt {
    text-transform: uppercase;
}

.count-down .c-grid,
.count-down-alt .c-grid {
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: 0 20px;
    padding-top: 42px;
    text-align: center;
}

.count-down.light-bg .c-grid,
.count-down-alt.light-bg .c-grid {
    background: #fff;
}

.count-down-alt .c-grid {
    border: 5px solid rgba(0, 0, 0, .2);
}

.count-down .c-grid {
    border: 1px solid #e0e0e0;
}

.count-down .c-grid,
.count-down.light .c-grid {
    border: 1px solid #fff;
}

.count-down.light .c-value,
.count-down.light .c-title,
.count-down-alt.light .c-value,
.count-down-alt.light .c-title {
    color: #fff;
}

.count-down .c-value,
.count-down .c-title,
.count-down-alt .c-value,
.count-down-alt .c-title {
    display: block;
}

.count-down .c-value,
.count-down-alt .c-value {
    font-size: 30px;
}

.count-down .c-title,
.count-down-alt .c-title {
    letter-spacing: 3px;
    font-size: 14px;
}

.count-down.circle .c-grid,
.count-down-alt.circle .c-grid {
    border-radius: 50% !important;
}

.count-down.round .c-grid,
.count-down-alt.round .c-grid {
    border-radius: 5px !important;
}

.count-parallax {
    background-image: url("/img/parallax/5.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.count-icon {
    position: absolute;
    top: 50px;
    right: 0;
}

.count-icon i {
    font-size: 40px;
}

/*==============================================
 Pagination
 ===============================================*/
.custom-pagination > li:first-child > a,
.custom-pagination > li:first-child > span,
.custom-pagination > li:last-child > a,
.custom-pagination > li:last-child > span {
    border-radius: 0;
}

.custom-pagination > li > a {
    margin: 0 3px;
    color: #424242;
}

.custom-pagination > li > a:hover {
    color: #000;
}

.custom-pagination.pagination > .active > a,
.custom-pagination.pagination > .active > a:focus,
.custom-pagination.pagination > .active > a:hover,
.custom-pagination.pagination > .active > span,
.custom-pagination.pagination > .active > span:focus,
.custom-pagination.pagination > .active > span:hover {
    z-index: 2;
    cursor: default;
    color: #fff;
    border-color: #222;
    background-color: #222;
}

/*==============================================
 Product
 ===============================================*/
.cart-info .megamenu-quarter-width {
    width: 450px !important;
}

.cart-table-list,
.cart-table-list i {
    color: #222;
    border: none;
}

header li.nav-icon .cart-table-list a {
    color: #222;
    line-height: normal;
}

.total-cart {
    display: inline-block;
    float: right;
    width: 100%;
    margin-bottom: 20px;
    padding-top: 20px;
    text-align: right;
    color: #222;
    border-top: 1px solid rgba(0, 0, 0, .2);
}

.total-cart ul {
    float: right;
    width: 100%;
    padding: 0;
    list-style: none;
}

.total-cart ul li {
    display: block;
    overflow: hidden;
}

.total-cart ul li span {
    display: inline-block;
    width: 100px;
    text-align: right;
    white-space: nowrap;
}

.cart-table-list a.close img {
    width: 11px;
    height: 11px;
}

.cart-table-list td img {
    width: 50px;
    height: auto;
}

.s-cart-btn a {
    margin: 0 0 10px 10px;
    color: #fff !important;
}

.cart-table-list.table > tbody > tr > td {
    height: 20px;
    vertical-align: middle;
    border: none;
    line-height: 20px;
}

.product-list {
    min-height: 440px;
    margin-bottom: 50px;
}

.product-list .product-img {
    position: relative;
    /*height: 340px;*/
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
}

.product-img:hover > a:nth-of-type(2) {
    z-index: 2;
    opacity: 1;
}

.product-img > a:nth-of-type(2) {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
}

.sale-label {
    position: absolute;
    z-index: 10;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    border-radius: 50%;
    line-height: 50px;
}

.product-img > a,
.product-img:hover > a {
    -webkit-transition: all .5s ease;
            transition: all .5s ease;
}

.product-list .product-img img,
.product-img img,
.product-img > a {
    display: block;
    width: 100%;
    height: 100%;
}

.product-title h5 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

.product-title h5 a {
    color: #222;
}

.product-price {
    margin-bottom: 5px;
    font-size: 18px;
}

.product-price del {
    color: #837f7e;
    font-size: 14px;
}

.product-rating {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
    color: #9b9a9a;
}

.product-cart {
    font-size: 12px;
}

.product-grid input {
    height: 40px;
    border-radius: 0;
    box-shadow: none;
}

.product-grid input:focus,
.product-grid .form-control:focus,
.portfolio-meta .form-control:focus {
    border-color: #000;
    box-shadow: none;
}

.product-grid button.btn,
.p-values,
.full-width button.btn {
    width: 100%;
}

.fit-img img {
    width: 100%;
    height: auto;
}

.product-grid .form-control,
.p-quantity .form-control,
.portfolio-meta .form-control {
    box-shadow: none;
}

.p-quantity {
    margin-bottom: 0;
    /*padding: 0;*/
    list-style: none;
}

.p-values {
    display: inline-block;
}

.p-values ul {
    float: left;
}

.p-quantity li {
    float: left;
    width: 130px;
    margin: 15px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000;
    font-size: 12px;
}

.p-quantity li label {
    padding-top: 7px;
    font-weight: normal;
}

.p-quantity .input-group-btn:first-child > .btn,
.p-quantity .input-group-btn:first-child > .btn-group {
    margin-right: -1px;
}

.p-quantity .btn.focus,
.p-quantity .btn:focus,
.p-quantity .btn:hover {
    color: #222 !important;
}

.product-grid .col-lg-1,
.product-grid .col-lg-10,
.product-grid .col-lg-11,
.product-grid .col-lg-12,
.product-grid .col-lg-2,
.product-grid .col-lg-3,
.product-grid .col-lg-4,
.product-grid .col-lg-5,
.product-grid .col-lg-6,
.product-grid .col-lg-7,
.product-grid .col-lg-8,
.product-grid .col-lg-9,
.product-grid .col-md-1,
.product-grid .col-md-10,
.product-grid .col-md-11,
.product-grid .col-md-12,
.product-grid .col-md-2,
.product-grid .col-md-3,
.product-grid .col-md-4,
.product-grid .col-md-5,
.product-grid .col-md-6,
.product-grid .col-md-7,
.product-grid .col-md-8,
.product-grid .col-md-9,
.product-grid .col-sm-1,
.product-grid .col-sm-10,
.product-grid .col-sm-11,
.product-grid .col-sm-12,
.product-grid .col-sm-2,
.product-grid .col-sm-3,
.product-grid .col-sm-4,
.product-grid .col-sm-5,
.product-grid .col-sm-6,
.product-grid .col-sm-7,
.product-grid .col-sm-8,
.product-grid .col-sm-9,
.product-grid .col-xs-1,
.product-grid .col-xs-10,
.product-grid .col-xs-11,
.product-grid .col-xs-12,
.product-grid .col-xs-2,
.product-grid .col-xs-3,
.product-grid .col-xs-4,
.product-grid .col-xs-5,
.product-grid .col-xs-6,
.product-grid .col-xs-7,
.product-grid .col-xs-8,
.product-grid .col-xs-9 {
    padding-right: 15px;
    padding-left: 15px;
}

.product-grid .row {
    margin-right: -15px;
    margin-left: -15px;
}

.stock .number-item {
    color: #757575;
    font-size: 30px;
}

.stock .status {
    display: initial;
    padding: 5px 10px;
    letter-spacing: normal;
    color: #fff;
    border-radius: 2px;
}

.review-avatar {
    width: 60px;
    height: auto;
    margin-right: 10px;
}

.review-rating i {
    padding: 0 !important;
}

.review-rating {
    float: right;
}

.cart-table {
    border: 1px solid #ddd;
}

.cart-table td a {
    color: #222;
}

.cart-table td a:hover {
    color: #000;
}

.cart-table thead th {
    text-transform: uppercase;
}

.cart-table.table > tbody > tr > td,
.cart-table.table > tbody > tr > th,
.cart-table.table > tfoot > tr > td,
.cart-table.table > tfoot > tr > th,
.cart-table.table > thead > tr > td,
.cart-table.table > thead > tr > th {
    vertical-align: middle;
}

.cart-img img {
    width: 60px;
    height: auto;
}

.cart-action input {
    padding: 6px 3px 6px 6px;
}

.cart-action input,
.cart-action button {
    float: left;
    margin-right: 3px;
}

.cart-action button:hover i {
    color: #222;
}

.cart-quantity {
    width: 45px;
    border-radius: 0;
}

.coupon-form input {
    height: 40px;
    border-radius: 0;
}

.coupon-form label {
    text-transform: uppercase;
    font-weight: normal;
}

.cart-total {
    font-size: 16px;
}

.cart-btn-row a {
    margin-right: 0;
    margin-left: 10px;
}

.coupon-form label {
    margin-right: 20px;
}

.coupon-form input {
    margin-right: 10px;
}

.pro-box .one-third,
.featured-box.pro-box .one-third:nth-child(2) {
    padding: 10px 5px;
}

.panel {
    margin-bottom: 20px;
    border: none;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
}

.shop-view-mode {
    display: inline-block;
    margin-top: 5px;
}

.shop-view-mode a {
    margin-left: 15px;
    color: #adadad;
}

.shop-view-mode a:hover {
    color: #222;
}

/*==============================================
 Boxed View
 ===============================================*/
.boxed.box-bg {
    background: #ddd;
}

.boxed .wrapper {
    position: relative;
    width: 1170px;
    margin: 0 auto;
    background: #fff;
}

.boxed header.sticky,
.boxed header.sticky .header-sticky,
.boxed header.transparent-header {
    width: 1170px;
}

.box-background-img {
    height: 100vh;
    background-image: url("/img/parallax/para7.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-size: cover;
}

.box-pattern {
    background-image: url("/img/pattern-2.png");
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: 50% 50%;
}

/*==============================================
 Blog
 ===============================================*/
.blog-classic {
    margin-bottom: 70px;
    padding-bottom: 70px;
    border-bottom: 1px solid #efefef;
}

.blog-classic .date {
    float: left;
    width: 100px;
    height: 100px;
    margin-right: 40px;
    padding-top: 15px;
    text-align: center;
    color: #daa97e;
    background: #efede1;
    font-size: 30px;
}

.blog-classic .date.right {
    float: right;
    margin-right: 0;
    margin-left: 40px;
}

.blog-classic .date span {
    display: block;
    font-size: 12px;
}

.blog-classic .blog-post {
    overflow: hidden;
}

.blog-classic .blog-post .full-width {
    margin-bottom: 50px;
}

.blog-post h4 a {
    color: #222;
}

.blog-post h4 {
    margin-bottom: 10px;
}

.post-meta {
    margin-bottom: 40px;
    padding: 0;
    list-style: none;
}

.post-meta li {
    display: inline-block;
    margin-right: 10px;
    text-transform: uppercase;
    color: #a7a7a7;
    font-size: 14px;
}

.post-meta li a {
    margin-right: 5px;
    color: #a7a7a7;
}

.post-meta li i {
    padding-right: 10px;
}

.blog-post blockquote {
    margin: 0 0 20px;
    padding: 0;
    border-left: none;
    font-size: 17.5px;
    font-style: italic;
}

.quote-post p:before {
    padding-right: 10px;
    content: "\f10d";
    color: #837f7e;
    font-family: "FontAwesome";
}

.quote-post p:after {
    padding-left: 10px;
    content: "\f10e";
    color: #837f7e;
    font-family: "FontAwesome";
}

.portfolio-with-title.blog-m .portfolio-title h4 a {
    font-size: 16px;
}

.portfolio-with-title.blog-m .portfolio-title p {
    letter-spacing: normal;
}

.video-fit {
    position: relative;
    width: 100%;
    padding-top: 50%;
}

.audio-fit {
    position: relative;
    width: 100%;
    padding-top: 20%;
}

.video-fit iframe,
.video-fit object,
.video-fit embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.audio-fit iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: none;
}

/* custom pagination */
.custom-pagination > li:first-child > a,
.custom-pagination > li:first-child > span,
.custom-pagination > li:last-child > a,
.custom-pagination > li:last-child > span {
    border-radius: 0;
}

.custom-pagination > li > a {
    margin: 0 3px;
    color: #424242;
}

.custom-pagination > li > a:hover {
    color: #000;
}

.custom-pagination.pagination > .active > a,
.custom-pagination.pagination > .active > a:focus,
.custom-pagination.pagination > .active > a:hover,
.custom-pagination.pagination > .active > span,
.custom-pagination.pagination > .active > span:focus,
.custom-pagination.pagination > .active > span:hover {
    z-index: 2;
    cursor: default;
    color: #fff;
    border-color: #daa97e;
    background-color: #daa97e;
}

/*search-row*/
.search-row {
    position: relative;
}

.search-btn {
    position: absolute;
    top: 50%;
    right: 1px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    color: #ccc;
    border: none;
    outline: none;
    background: transparent;
    font-size: 14px;
    line-height: 36px;
}

.search-row input {
    width: 100% !important;
    height: 40px;
    border-radius: 0;
    box-shadow: none;
}

.search-row input:focus {
    border-color: #333;
    outline: 0;
    box-shadow: none;
}

.widget {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.widget h6 {
    font-size: 18px;
    font-weight: bold;
}

.widget .avatar {
    display: block;
    margin-bottom: 30px;
}

.widget-latest-post,
.widget-category,
.widget-comments {
    padding: 0;
    list-style: none;
}

.widget-latest-post li,
.widget-category li,
.widget-comments li {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}

.widget-category li,
.widget-comments li {
    margin-bottom: 20px;
}

.widget-category li a,
.widget-comments li a {
    color: #837f7e;
}

.widget-category li a:before {
    padding-right: 10px;
    content: "\f105";
    font-family: "FontAwesome";
}

.widget-comments li:before {
    padding-right: 10px;
    content: "\f0e6";
    color: #837f7e;
    font-family: "FontAwesome";
}

.widget-latest-post li:last-child {
    margin-bottom: 0;
}

.widget-latest-post .thumb {
    float: left;
    width: 75px;
    height: auto;
    margin-right: 20px;
}

.widget-latest-post .thumb img {
    width: 100%;
    height: 100%;
}

.widget-latest-post .w-desk {
    display: inherit;
    overflow: hidden;
    color: #9b9a9a;
}

.widget-latest-post .w-desk a {
    display: block;
    text-transform: uppercase;
    color: #333;
    font-size: 13px;
}

.widget-social-link a {
    float: left;
}

.widget-social-link a {
    width: 35px;
    height: 35px;
    margin: 0 15px 15px 0;
    text-align: center;
    color: #5a5a5a;
    border: 1px solid #5a5a5a;
    font-size: 16px;
    line-height: 35px;
}

.widget-social-link a:hover,
.widget-tags a:hover {
    color: #fff;
}

.circle a {
    border-radius: 50%;
}

.widget-tags a {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 3px 10px;
    color: #837f7e;
    border: 1px solid #d3d3d3;
    border-radius: 50px;
}

/*------------------------------
blog-masonry
------------------------------*/
.portfolio-with-title.blog-m .portfolio-title p {
    margin: 5px 0 0 0;
    text-transform: none;
    color: #757575;
    font-family: 'Open Sans', 'PMingLiU', sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 26px;
}

.portfolio-with-title.blog-m .portfolio-title p {
    display: block; 
    margin-bottom: 20px;
}

.portfolio-with-title.blog-m .portfolio-title .date {
    display: block;
    margin: 5px 0 20px 0;
    letter-spacing: 1px;
    text-transform: uppercase; 
    font-size: 12px;
}

.portfolio-with-title.blog-m .portfolio-title .date,
.portfolio-with-title.blog-m .portfolio-title .date a {
    color: #a8a8a8;
}

/*------------------------------
comments-list
------------------------------*/
.comments-list .comment-author a {
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #222;
    font-size: 12px; 
    /*font-family: 'Abel', sans-serif;*/
    font-weight: bold;
}

.comments-list .media > .pull-left {
    padding-right: 20px;
}

.comments-list .comment-info,
.comments-list .comment-info a {
    color: #a7a7a7;
}

.comments-list .comment-info .comment-author a {
    color: #222;
}

.comments-list .comment-info i {
    padding: 0 10px;
}

.comments-list .comment-avatar {
    width: 50px;
    height: auto;
}

.blog-comments .col-md-6,
.blog-comments .col-md-12 {
    padding-right: 15px;
    padding-left: 15px;
}

.blog-comments .form-control {
    border-radius: 0;
    box-shadow: none;
}

.blog-comments .form-control:focus {
    border-color: #222;
}

/*----*/
.pagination-row {
    overflow: hidden;
}

.pagination-row {
    margin-bottom: 60px; 
    padding: 30px 0;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
}

.pagination-post .prev-post {
    float: left;
    text-align: left;
}

.pagination-post .prev-post,
.pagination-post .next-post {
    width: 45%;
}

.pagination-post .post-list-link {
    float: left;
    width: 10%;
    margin-top: 5px; 
    text-align: center;
}

.pagination-post .next-post {
    float: right;
    text-align: right;
}

.pagination-post .prev-post .arrow {
    float: left;
    margin-right: 20px;
}

.pagination-post .arrow {
    color: #e5e5e5;
    font-size: 50px;
    line-height: 50px;
}

.pagination-post .next-post .arrow {
    float: right;
    margin-left: 20px;
}

.pagination-post .post-list-link a {
    color: #d2d2d2;
    outline: none; 
    font-size: 30px;
}

.pagination-post .pagination-txt {
    margin-top: 18px;
}

.pagination-post a {
    color: #d2d2d2;
}

.pagination-post a:hover,
.pagination-post a:hover .arrow {
    color: #222;
}

.pagination-post a,
.pagination-post a .arrow,
.pagination-post a:hover,
.pagination-post a:hover .arrow {
    -webkit-transition: all .3s;
            transition: all .3s;
}

/*==============================================
 Post
 ===============================================*/
.video-fit {
    position: relative; 
    width: 100%;
    padding-top: 50%;
}

.audio-fit {
    position: relative; 
    width: 100%;
    padding-top: 20%;
}

.video-fit iframe,
.video-fit object,
.video-fit embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.audio-fit iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /*height: 100%;*/
    border: none;
}

/*==============================================
 TB Grid
 ===============================================*/
.tb-grid {
    width: 100%;
    margin: auto;
}
.tb-grid .box,
.tb-grid .box2,
.tb-grid .box3 {
    display: inline-block;
}
.tb-grid .box {
    width: 33.3333%;
    padding-top: 33.3333%;
}
.tb-grid .box2 {
    width: 66.6666%;
    padding-top: 33.3333%;
}
.tb-grid .box3 {
    width: 66.6666%;
    padding-top: 66.6666%;
}
.tb-grid .box-nano {
    position: relative;
    float: left; 
    width: 50%;
    margin: 0;
    padding-top: 50%;
}
.tb-grid .item > .item-inner,
.tb-grid .box-nano > .item-inner {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    margin: 0;
    cursor: crosshair; 
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}
.tb-grid .items > .item-inner {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

/*==============================================
 Typist
 ===============================================*/
@-webkit-keyframes blink {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: .0;
    }
    100% {
        opacity: 1.0;
    }
}
@keyframes blink {
    0% {
        opacity: 1.0;
    }
    50% {
        opacity: .0;
    }
    100% {
        opacity: 1.0;
    }
}

.typist-blink:after {
    position: relative;
    top: 10px;
    display: inline-block;
    height: 47px;
    margin-right: 7px;
    margin-left: 3px;
    content: " ";
    -webkit-animation: blink 1s step-start 0s infinite;
            animation: blink 1s step-start 0s infinite; 
    border-right: 2px solid;
}

.typist-blink > .selectedText {
    display: none;
}

.typist-mark > .selectedText {
    color: #fff;
    background-color: #222;
    font-style: normal;
}

/*==============================================
 Footer
 ===============================================*/
#footer.gray {
    padding: 80px 0; 
    background: #f5f5f5;
}

#footer.dark {
    background: #efede1;
}

#footer.rice {
    background: #efede1;
}

#footer.gray .sub-title {
    margin: 15px 0; 
    letter-spacing: 2px;
    color: #222;
    font-size: 12px;
}

#footer.dark .sub-title {
    margin: 15px 0; 
    letter-spacing: 2px;
    color: #fff;
    font-size: 12px;
}

#footer.gray .social-link a {
    display: inline-block;
    margin: 10px; 
    color: #222;
    font-size: 20px;
}

#footer.dark .social-link li {
    display: inline-block;
    margin: 5px 0;
    color: #333;
    font-size: 15px;
}

.social-link li:last-child:after {
    content: '';
}

.social-link li:after {
    content: '|';
    margin-left: 5px;
    font-size: 18px;
}

#footer.gray .social-link.circle a:hover {
    color: #fff;
    border: 1px solid transparent; 
    background: #222;
}

#footer.dark .social-link.circle a:hover {
    color: #222;
    border: 1px solid transparent; 
    background: #fff;
}

#footer.gray .copyright {
    letter-spacing: 2px; 
    color: #000;
    font-size: 14px;
}

#footer.dark .copyright {
    letter-spacing: 2px; 
    color: #fff;
    font-size: 14px;
}

#footer.gray .copyright-sub-title {
    letter-spacing: 2px; 
    font-size: 12px;
}

.footer-logo {
    display: block;
}

.f-link,
#footer.dark h5 {
    color: #fff;
}

#footer.dark h5 {
    letter-spacing: 1px;
}

#footer.dark {
    color: #6f7273;
}

#footer.dark .primary-footer {
    padding: 80px 0; 
    background: #222;
}

#footer .f-list,
.r-work {
    margin: 0;
    padding: 0;
    list-style: none;
}

#footer .f-list li a {
    display: block; 
    color: #a2a2a2;
    line-height: 45px;
}

#footer .f-list li {
    border-bottom: 1px solid #333;
}

#footer .f-list li a:before {
    padding-right: 15px; 
    content: "\f105";
    font-family: "fontawesome";
}

.r-work li a {
    float: left;
    margin: 0 10px 10px 0;
}

.r-work li a {
    width: 55px;
    height: 55px;
}

.r-work li a img {
    width: 100%;
    height: auto;
}

#footer.dark .secondary-footer {
    display: inline-block;
    width: 100%;
    padding: 35px 0;
    border-top: 1px solid #2c2c2c;
    background: #1b1b1b;
    font-weight: normal;
}

#footer.dark .secondary-footer .social-link a {
    display: inline-block;
    margin: 0 0 0 15px; 
    color: #5a5a5a;
    font-size: 20px;
}

#footer.dark .secondary-footer .social-link.circle a {
    width: 35px;
    height: 35px;
    text-align: center; 
    border: 1px solid #5a5a5a;
    border-radius: 50%;
    line-height: 35px;
}

#footer.dark .secondary-footer .social-link.circle a:hover {
    color: #fff;
    border: 1px solid transparent; 
    background: #5a5a5a;
}

.footer-3,
.footer-4 {
    position: relative;
}

.footer-3 .f-logo-center {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -3%;
    padding: 20px;
    text-align: center;
    border: 5px solid #f5f5f5; 
    background-color: #fff;
}

.footer-4 .f-logo-center {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -5%;
    padding: 20px;
    text-align: center;
    border: 5px solid #222; 
    background-color: #fff;
}

.footer-4.dark {
    padding: 80px 0; 
    background: #222;
}

.footer-4.dark .social-link a {
    display: inline-block;
    margin: 10px; 
    color: #fff;
    font-size: 20px;
}

.footer-4.dark .social-link.circle a {
    width: 40px;
    height: 40px;
    text-align: center; 
    border: 1px solid #fff;
    border-radius: 50%;
    line-height: 40px;
}

.footer-4.dark .social-link.circle a:hover {
    color: #222;
    border: 1px solid transparent; 
    background: #fff;
}

.footer-4.dark .copyright {
    letter-spacing: 2px; 
    color: #fff;
    font-size: 14px;
}

.footer-4.dark .copyright-sub-title {
    letter-spacing: 2px;
    color: #d0d0d0; 
    font-size: 12px;
}

.gray .border-top {
    padding-top: 40px; 
    border-top: 1px dashed rgba(0, 0, 0, .1);
}

.dark .border-top {
    padding-top: 20px;
    border-top: 1px dashed rgba(0, 0, 0, .3);
}

.short-pad {
    padding: 40px 0 !important;
}

/*==============================================
 Pages
 ===============================================*/
.maintenance-banner {
    background: #ededed;
}

.maintenance-banner p {
    font-size: 16px;
}

/*------------------------------------------------------------------
    login
-------------------------------------------------------------------*/
.login {
    position: relative;
}

.login .form-control {
    height: 40px; 
    box-shadow: none;
}

.login .form-group {
    margin-bottom: 20px;
}

.login .form-group label {
    font-weight: normal;
}

.login .container {
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -330px;
}

.login.register .container {
    position: static;
    top: auto;
    width: 100%;
    margin-top: 0;
}

.login-bg {
    height: 100vh; 
    background: #f5f5f5;
}

.login-parallax {
    background-image: url("/img/parallax/3.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
}

.login-parallax .login-box {
    background: rgba(255, 255, 255, .9);
}

.login-box {
    width: 450px;
    margin: 0 auto;
    padding: 30px; 
    background: #fff;
}

.login-box.gray-bg {
    width: auto; 
    background: #f5f5f5;
}

.login-logo {
    padding: 50px 0;
}

.full-width {
    position: relative; 
    width: 100%;
}

.login-social-link {
    display: inline-block;
    width: 100%; 
    margin-bottom: 15px;
}

.login-social-link a.facebook {
    float: left; 
    margin-right: 22px;
    background: #3b5999;
}

.login-social-link a.facebook:hover {
    color: #fff; 
    background: #30497d;
}

.login-social-link a {
    width: 47%;
    padding: 10px 38px;
    text-align: center; 
    color: #fff;
    border-radius: 4px;
}

.login-social-link a.twitter {
    float: left; 
    background: #63c6ff;
}

.login-social-link a.twitter:hover {
    color: #fff; 
    background: #4c98c4;
}

.copyright-row {
    margin-top: 20px; 
    color: #fff;
}

/**/
.login-accordion > dd {
    padding: 10px 0;
}

.login-accordion .login .form-control,
.tab-pane .form-control {
    border-radius: 0;
}

.dark-txt {
    color: #222;
}

.border-less-input input {
    border: none;
}

.login-bg-input input {
    background: #f5f5f5;
}

.contact-comments .col-md-6,
.contact-comments .col-md-12 {
    padding-right: 15px;
    padding-left: 15px;
}

.form-control {
    border: 1px solid #e5e5e5;
    border-radius: 3px; 
    box-shadow: none;
}

.form-control:focus {
    border-color: #222;
    box-shadow: none;
}

.contact-comments .form-control {
    height: 40px; 
    border-radius: 0;
}

.contact-comments .cmnt-text {
    height: 95px;
}

.contact-comments label {
    text-transform: uppercase;
    color: #7e7e7e; 
    font-weight: normal;
}

address p {
    margin-bottom: 0;
}

.ticket-register .form-control {
    height: 40px;
    color: #a7a7a7; 
    border-radius: 5px;
}

.ticket-register .form-group,
.register-member a {
    margin: 0 5px;
}

.register-member a {
    display: inline-block;
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.register-member a img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.magazine-slider {
    margin-top: 0;
}

.magazine-slider .container {
    width: auto;
}

.magazine-grid .row {
    margin-right: -10px;
    margin-left: -10px;
}

.magazine-grid .col-lg-1,
.magazine-grid .col-lg-10,
.magazine-grid .col-lg-11,
.magazine-grid .col-lg-12,
.magazine-grid .col-lg-2,
.magazine-grid .col-lg-3,
.magazine-grid .col-lg-4,
.magazine-grid .col-lg-5,
.magazine-grid .col-lg-6,
.magazine-grid .col-lg-7,
.magazine-grid .col-lg-8,
.magazine-grid .col-lg-9,
.magazine-grid .col-md-1,
.magazine-grid .col-md-10,
.magazine-grid .col-md-11,
.magazine-grid .col-md-12,
.magazine-grid .col-md-2,
.magazine-grid .col-md-3,
.magazine-grid .col-md-4,
.magazine-grid .col-md-5,
.magazine-grid .col-md-6,
.magazine-grid .col-md-7,
.magazine-grid .col-md-8,
.magazine-grid .col-md-9,
.magazine-grid .col-sm-1,
.magazine-grid .col-sm-10,
.magazine-grid .col-sm-11,
.magazine-grid .col-sm-12,
.magazine-grid .col-sm-2,
.magazine-grid .col-sm-3,
.magazine-grid .col-sm-4,
.magazine-grid .col-sm-5,
.magazine-grid .col-sm-6,
.magazine-grid .col-sm-7,
.magazine-grid .col-sm-8,
.magazine-grid .col-sm-9,
.magazine-grid .col-xs-1,
.magazine-grid .col-xs-10,
.magazine-grid .col-xs-11,
.magazine-grid .col-xs-12,
.magazine-grid .col-xs-2,
.magazine-grid .col-xs-3,
.magazine-grid .col-xs-4,
.magazine-grid .col-xs-5,
.magazine-grid .col-xs-6,
.magazine-grid .col-xs-7,
.magazine-grid .col-xs-8,
.magazine-grid .col-xs-9 {
    padding-right: 10px; 
    padding-left: 10px;
}

.aside-social {
    margin-bottom: 20px; 
    border: 1px solid #ddd;
    font-size: 0;
}

.aside-social li {
    width: 25%; 
    padding-top: 8px;
    -webkit-transition: background .3s ease 0s;
            transition: background .3s ease 0s;
    text-align: center;
    border-left: 1px solid #ddd;
}

.aside-social li:hover {
    -webkit-transition: background .3s ease 0s;
            transition: background .3s ease 0s;
    color: #fff; 
    border-left: 1px solid #333;
    background: #333;
}

.aside-social li:hover i,
.aside-social li:hover p {
    -webkit-transition: .3s ease 0s;
            transition: .3s ease 0s; 
    color: #fff;
}

.aside-social li:first-child {
    border-left: medium none;
}

.aside-social.list-inline > li {
    display: inline-block;
}

.aside-social.list-inline {
    margin-left: 0;
}

.aside-social li i {
    margin-top: 5px;
    -webkit-transition: .3s ease 0s;
            transition: .3s ease 0s; 
    color: #aaa;
    font-size: 16px;
}

.aside-social li p {
    margin-top: 5px;
    margin-bottom: 6px;
    -webkit-transition: .3s ease 0s;
            transition: .3s ease 0s; 
    font-size: 11px;
}

.newsletter {
    margin-bottom: 20px;
    padding: 20px; 
    color: #fff;
    background: url("/img/parallax/3.jpg") repeat 0 0/cover;
}

.newsletter h3 {
    margin-bottom: 10px;
}

.newsletter p {
    line-height: 20px;
}

.round-less {
    border-radius: 0;
}

.magazine-grid .post-single .post-desk {
    height: auto;
}

.magazine-latest-news {
    position: relative; 
    padding: 20px;
    background: #EFECE2;
}

.breaking {
    position: absolute;
    top: 0;
    left: 0;
    float: left;
    margin-right: 30px;
    padding: 20px;
    text-transform: uppercase; 
    color: #fff;
    background: #DAA87C;
}

.breaking:after {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 0;
    height: 0;
    margin-top: -10px; 
    content: " ";
    pointer-events: none;
    border: solid transparent;
    border-width: 10px;
    border-color: rgba(51, 51, 51, 0);
    border-left-color: #DAA87C;
}

.magazine-latest-news .news-slider {
    margin: 0  150px 0 80px;
}

.magazine-latest-news .flex-control-nav {
    bottom: 25px;
}

.magazine-latest-news a {
    color: #333;
}

.catering-form .form-control {
    height: 65px;
    border: none;
}

.catering-form input {
    width: 400px !important;
}

.catering-form select {
    width: 200px !important;
}

.app-down-row {
    position: relative;
    height: 510px;
}

.app-down-row .cater-avatar {
    position: absolute;
    top: -65px; 
    left: 0;
}

.app-btn a {
    margin-right: 10px;
}

.catering-list .post-single .post-desk {
    height: 180px; 
    padding: 30px 0;
}

.app-down-row .app-info {
    font-weight: 300;
}

.app-down-row .app-info span {
    font-weight: bold;
}

.float-left {
    float: left;
}

.txt-extra-xl {
    font-size: 70px;
}

.light-txt-trans {
    color: rgba(255, 255, 255, .5);
}

.f-menu-desk {
    margin-bottom: 15px; 
    color: #959595;
}

.amenities-list li:last-child {
    border-bottom: 1px solid #9e9e9e;
}

.amenities-list li {
    padding: 10px 0; 
    border-top: 1px solid #9e9e9e;
}

.hotel-intro-box-resize .post-single {
    width: 250px;
}

.app-device {
    /*position: relative;*/
}

.app-device img {
    position: absolute;
    right: 10%;
    bottom: 0;
}

.app-img-bottom {
    min-height: 600px;
}

.app-img-bottom img {
    position: absolute;
    bottom: 0; 
    left: 15%;
}

.app-icon i {
    margin-right: 15px;
    color: #dedede; 
    font-size: 30px;
}

.banner-intro {
    background-image: url("/img/intro/intro-banner.jpg") !important;
}

.intro-title h2 {
    font-size: 36px;
}

.intro-title p {
    letter-spacing: 2px; 
    color: #fff !important;
    font-size: 16px;
    font-weight: 300;
}

.intro-title p span {
    font-size: 26px;
}

.intro-title i {
    position: relative;
    top: 4px;
    right: 10px; 
    color: rgba(255, 255, 255, .5);
    font-size: 26px;
    line-height: 20px;
}

.intro-title .btn {
    line-height: 32px;
}

.intro-title a:hover i,
.intro-title a:focus i {
    color: #222;
}

.intro-portfolio img {
    margin-bottom: 30px;
}

.intro-portfolio .fit-img {
    margin-bottom: 20px;
}

.intro-portfolio a img,
.intro-portfolio a img:hover {
    -webkit-transition: all .3s ease;
            transition: all .3s ease;
}

.intro-portfolio a img:hover {
    opacity: .8;
}

.intro-portfolio a {
    text-transform: uppercase;
    color: #222;
}

.dark-bg-alt .intro-portfolio a {
    text-transform: uppercase;
    color: #fff;
}

.travel-booking {
    position: relative;
    height: 100vh; 
    background-image: url("/img/banner/travel.jpg");
}

.travel-form-bg {
    display: inline-block;
    width: 100%; 
    background: rgba(0, 0, 0, .7);
}

.travel-form-bg-light {
    padding: 30px 0; 
    background: rgba(0, 0, 0, .3);
}

.travel-form-bg ul {
    margin-bottom: 0;
    margin-left: 15px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .travel-form-bg ul {
        margin-bottom: -1px;
        margin-left: 15px;
    }
}

.travel-form {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.travel-form.icon-tabs li i {
    display: inline-block !important;
}

.travel-form.icon-tabs li i {
    position: relative;
    top: 7px;
    padding-right: 10px; 
    font-size: 35px;
}

.travel-form.icon-tabs .nav > li {
    margin-left: 0; 
    padding: 0;
}

.travel-form.icon-tabs .nav > li > a {
    padding: 30px 40px;
}

.travel-form.icon-tabs .nav > li > a:hover {
    /*background: #fff;*/
}

.travel-form.icon-tabs .nav-pills > li > a:hover,
.travel-form.icon-tabs .nav-pills > li.active:hover > a,
.travel-form.icon-tabs .nav-pills > li.active > a,
.travel-form.icon-tabs .nav-pills > li.active > a:focus {
    color: #fff; 
    border-bottom: none;
}

.travel-form .form-control,
.travel-form .btn {
    height: 50px;
    border-color: transparent;
    border-radius: 4px;
}

.travel-form .btn {
    margin-top: 35px;
}

.travel-form .form-group {
    float: left;
    width: 15%;
    margin-right: 10px;
}

.travel-form .form-group label {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
}

.travel-grid .col-md-6 {
    padding: 0;
}

.travel-grid .post-list-aside .post-img img {
    border: none;
}

.travel-grid .post-list-aside {
    float: left;
}

.travel-grid .post-desk {
    display: inline-block;
    height: auto; 
    padding: 50px 30px;
}

.featured-item.feature-outline.travel-feature {
    position: relative;
    padding: 0 0 0 130px;
}

.fashion-title h1 {
    margin-bottom: 0; 
    letter-spacing: 5px;
    font-size: 100px;
    font-weight: 100;
}

.fashion-footer p {
    font-size: 13px; 
    line-height: 20px;
}

.fashion-footer .subscribe-form {
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
}

.fashion-footer .subscribe-form input {
    width: 65% !important;
    border-radius: 4px; 
    background: #f8f8f8;
}

.fashion-footer ul {
    padding: 0;
    list-style: none;
}

.fashion-footer ul li a {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #6f7273;
    font-size: 13px;
}

.fashion-footer ul li a:hover {
    color: #222;
}

.fashion-footer .social-link a {
    display: inline-block;
    width: 35px;
    height: 35px;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    text-align: center;
    color: #cdcdcd;
    border: 1px solid #cdcdcd;
    border-radius: 50%; 
    line-height: 35px;
}

.fashion-footer .social-link a:hover {
    color: #fff;
    border: 1px solid transparent; 
    background: #5a5a5a;
}

.wedding-font {
    text-transform: none; 
    font-family: "Arizonia", cursive;
}

.bride-groom-txt {
    font-size: 100px !important;
    line-height: normal;
}

.wd-font-50 {
    font-size: 50px !important;
}

.wd-font-72 {
    font-size: 72px !important;
}

.wd-font-light {
    font-weight: 300 !important;
}

.bride-groom-row {
    padding: 0 50px;
}

.bride-groom-row .team-img {
    width: 300px;
    height: 300px;
    margin: 0 auto;
}

.bride-groom-row .team-img img {
    width: 100%;
    height: auto;
}

.wed-thumb,
.bride-groom-row .team-img.fnf-img {
    display: inline-block; 
    width: 160px;
    height: 160px;
}

.wed-thumb img,
.bride-groom-row .team-img.fnf-img img {
    width: 100%;
    height: auto;
    border: 10px solid #fff; 
    border-radius: 50%;
}

.m-top-negative {
    position: relative;
    top: -80px;
}

.pad-less {
    padding: 0;
}

.wedding-form .form-control {
    border-radius: 5px;
}

.wd-photo-border {
    border: 10px solid #f2f3f3 !important;
}

.heart-space {
    padding: 0 10px; 
    font-size: 30px;
}

.decor-category a {
    position: relative; 
    display: block;
    overflow: hidden;
    margin-bottom: 20px;
}

.decor-category a img {
    -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
}

.decor-category a:hover img {
    -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
            transform: scale(1.3);
}

.decor-category a .title {
    position: absolute;
    top: 35%;
    right: 10%;
    text-align: right;
    text-transform: uppercase; 
    font-size: 24px;
    font-weight: 300;
}

.decor-category a .title.dark {
    color: #222;
}

.decor-category a .title.light {
    color: #fff;
}

.portfolio-meta {
    padding: 0; 
    list-style: none;
}

.portfolio-meta li {
    margin: 15px 0;
    color: #666;
}

.portfolio-meta li span {
    display: inline-block;
    width: 80px;
    letter-spacing: 2px;
    text-transform: uppercase; 
    color: #000;
    font-size: 12px;
    text-align: right;
    padding-right: 10px;
}

.portfolio-meta li a {
    margin-right: 10px;
    color: #cdcdcd;
}

.portfolio-meta li a:hover {
    color: #323232;
}

/*------------------------------------------------------------------
    error 404
-------------------------------------------------------------------*/
.error404 {
    display: block;
    margin-top: 20px;
    color: #222;
    font-size: 140px;
    font-weight: 100; 
    line-height: 140px;
}

.error-txt {
    display: block; 
    margin-bottom: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 22px;
}

.error-info {
    padding-left: 60px;
    border-left: 1px solid #ddd;
}

.error-parallax {
    height: 100vh; 
    background-image: url("/img/parallax/404_parallax.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.error-parallax .error-avatar {
    display: block;
    margin-top: 180px;
    margin-bottom: 50px;
}

.error-parallax .error-info {
    padding-left: 0;
    border-left: none;
}

.error-parallax .error-info .error404,
.error-parallax .error-info .error-txt {
    color: #fff;
}

.gym-schedule {
    display: block;
}
.gym-schedule li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    font-size: 16px;
}
.gym-schedule li i {
    padding-right: 10px;
}
.gym-schedule li .opening-hour {
    float: right; 
    text-align: right;
}

/*==============================================
 Mailchimp
 ===============================================*/
.mailchimp label {
    font-weight: normal;
}

/*==============================================
 Scroll Top
 ===============================================*/
.lift-off {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    -webkit-transition: all .3s;
            transition: all .3s; 
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, .6);
    line-height: 40px;
}

.lift-off_show {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0); 
    opacity: 1;
}

.lift-off_hide {
    visibility: hidden;
    -webkit-transform: translateY(40px);
        -ms-transform: translateY(40px);
            transform: translateY(40px); 
    opacity: 0;
}

/*==============================================
 Background Images
 ===============================================*/
.home-banner {
    background-image: url("/img/banner/index-banner-2.jpg") !important;
}

.creative-banner {
    background-image: url("/img/banner/creative-banner.jpg") !important;
}

.banner-catering {
    background-image: url("/img/banner/catering.jpg") !important;
}

.banner-hotel {
    background-image: url("/img/hotel/hotel_banner.jpg") !important;
}

.banner-ban2 {
    background-image: url("/img/banner/ban2.jpg") !important;
}

.banner-01 {
    background-image: url("/img/banner/01.jpg") !important;
}

.banner-02 {
    background-image: url("/img/banner/02.jpg") !important;
}

.banner-04 {
    background-image: url("/img/banner/04.jpg") !important;
}

.banner-12 {
    background-image: url("/img/banner/12.jpg") !important;
}

.banner-13 {
    background-image: url("/img/banner/13.jpg") !important;
}

.banner-14 {
    background-image: url("/img/banner/14.jpg") !important;
}

.banner-15 {
    background-image: url("/img/banner/15.jpg") !important;
}

.banner-16 {
    background-image: url("/img/banner/16.jpg") !important;
}

.banner-17 {
    background-image: url("/img/banner/17.jpg") !important;
}

.banner-19 {
    background-image: url("/img/banner/19.jpg") !important;
}

.banner-20 {
    background-image: url("/img/banner/20.jpg") !important;
}

.banner-22 {
    background-image: url("/img/banner/22.jpg") !important;
}

.banner-23 {
    background-image: url("/img/banner/23.jpg") !important;
}

.banner-24 {
    background-image: url("/img/banner/24.jpg") !important;
}

.banner-25 {
    background-image: url("/img/banner/25.jpg") !important;
}

.banner-26 {
    background-image: url("/img/banner/26.jpg") !important;
}

.banner-27 {
    background-image: url("/img/banner/27.jpg") !important;
}

.banner-28 {
    background-image: url("/img/banner/28.jpg") !important;
}

.banner-07 {
    background-image: url("/img/banner/07.jpg") !important;
}

.banner-08 {
    background-image: url("/img/banner/08.jpg") !important;
}

.banner-09 {
    background-image: url("/img/banner/09.jpg") !important;
}

.banner-10 {
    background-image: url("/img/banner/10.jpg") !important;
}

.banner-11 {
    background-image: url("/img/banner/11.jpg") !important;
}

.banner-05 {
    background-image: url("/img/banner/05.jpg") !important;
}

.banner-06 {
    background-image: url("/img/banner/06.jpg") !important;
}

.banner-p {
    background-image: url("/img/product/p-banner.jpg") !important;
}

.banner-r {
    background-image: url("/img/real-state/r-banner.jpg") !important;
}

.banner-spa {
    background-image: url("/img/spa/spa-banner.jpg") !important;
}

.banner-box1 {
    background-image: url("/img/banner/box_banner1.jpg") !important;
}

.banner-box2 {
    background-image: url("/img/banner/box_banner2.jpg") !important;
}

.banner-box-i {
    background-image: url("/img/banner/box_banner.jpg") !important;
}

.banner-flex3 {
    background-image: url("/img/banner/flex/flex3.jpg") !important;
}

.banner-demo {
    background-image: url("/img/banner/demo.jpg") !important;
}

.banner-onedot {
    background-image: url("/img/banner/onedot.jpg") !important;
}

.banner-02p {
    background-image: url("/img/banner/02p.jpg") !important;
}

.banner-app {
    background-image: url("/img/app/app_banner.jpg") !important;
}

.banner-para {
    background-image: url("/img/parallax/para_banner.jpg") !important;
}

.banner-para1 {
    background-image: url("/img/parallax/para1.jpg") !important;
}

.banner-para2 {
    background-image: url("/img/parallax/para2.jpg") !important;
}

.banner-para3 {
    background-image: url("/img/parallax/para3.jpg") !important;
}

.banner-para4 {
    background-image: url("/img/parallax/para4.jpg") !important;
}

.banner-para5 {
    background-image: url("/img/parallax/para5.jpg") !important;
}

.banner-para6 {
    background-image: url("/img/parallax/para6.jpg") !important;
}

.banner-para7 {
    background-image: url("/img/parallax/para7.jpg") !important;
}

.banner-para8 {
    background-image: url("/img/parallax/para8.jpg") !important;
}

.banner-sb1 {
    background-image: url("/img/about/sb-1.jpg") !important;
}

.banner-03 {
    background-image: url("/img/about/03.jpg") !important;
}

.banner-contact {
    background-image: url("/img/contact-banner.jpg") !important;
}

/*--------------------------------------
    parallax images
--------------------------------------*/
.parallax-4 {
    background-image: url("/img/parallax/4.jpg") !important;
}

.parallax-5 {
    background-image: url("/img/parallax/5.jpg") !important;
}

.parallax-6 {
    background-image: url("/img/parallax/6.jpg") !important;
}

.parallax-7 {
    background-image: url("/img/parallax/7.jpg") !important;
}

.parallax-8 {
    background-image: url("/img/parallax/8.jpg") !important;
}

.parallax-9 {
    background-image: url("/img/parallax/9.jpg") !important;
}

.parallax-10 {
    background-image: url("/img/parallax/10.jpg") !important;
}

.parallax-11 {
    background-image: url("/img/parallax/11.jpg") !important;
}

.parallax-12 {
    background-image: url("/img/parallax/12.jpg") !important;
}

.parallax-13 {
    background-image: url("/img/parallax/13.jpg") !important;
}

.parallax-14 {
    background-image: url("/img/parallax/14.jpg") !important;
}

.parallax-15 {
    background-image: url("/img/parallax/15.jpg") !important;
}

.parallax-16 {
    background-image: url("/img/parallax/16.jpg") !important;
}

.parallax-17 {
    background-image: url("/img/parallax/17.jpg") !important;
}

.parallax-18 {
    background-image: url("/img/parallax/18.jpg") !important;
}

.parallax-19 {
    background-image: url("/img/parallax/19.jpg") !important;
}

.parallax-p15 {
    height: 599px !important; 
    background: url("/img/post/p15.jpg") center center no-repeat !important;
    background-size: cover !important;
}

.fast-food {
    background: url("/img/restaurant/fast-food.jpg") right 30px no-repeat !important;
}

.parallax-hot {
    background-image: url("/img/hotel/hotparallax.jpg") !important;
}

.parallax-ev-p {
    background-image: url("/img/parallax/ev-p.jpg") !important;
}

.parallax-p {
    background-image: url("/img/product/p-parallax.jpg") !important;
}

.parallax-r {
    background-image: url("/img/real-state/r-parallax.jpg") !important;
}

.parallax-r-2 {
    background-image: url("/img/real-state/r-parallax-2.jpg") !important;
}

.parallax-s {
    background-image: url("/img/spa/s-parallax.jpg") !important;
}

/**/
.post-p8 {
    background: url("/img/post/p8.jpg") right 100px no-repeat !important;
}

.post-p9 {
    background: url("/img/post/p9.jpg") left 150px no-repeat !important;
}

.post-p9-alt {
    background: url("/img/post/p9.jpg") left 100px no-repeat !important;
}

/*==============================================
 Responsive
 ===============================================*/
/*------------------------------------------------------------------
Responsive Stylesheet

Project:   Massive - Multipurpose Responsive Theme
Build:     Bootstrap 3 +
Author:    ThemeBucket

-------------------------------------------------------------------*/
/*------------------------------------------------------------------
Table of contents

1. Small Desktop
2. Tablet Landscape
3. Tablet Portrait
4. Phone
5. Small Phone
-------------------------------------------------------------------*/
/* -------------------------------
   Small desktop
   ------------------------------- */
/* -------------------------------
   Tablet Landscape
   ------------------------------- */
@media screen and (max-width: 1024px) {
    .login-box.gray-bg {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 992px) {
    /*portfolio   grid*/
    .portfolio.col-2 .portfolio-item,
    .portfolio.col-3 .portfolio-item,
    .portfolio.col-4 .portfolio-item,
    .portfolio.col-5 .portfolio-item,
    .portfolio.col-6 .portfolio-item {
        width: 33.3333333%;
        /*width: 50%;*/
    }
    .social-link.pull-right {
        float: left !important;
        margin-top: 10px;
    }
    #footer.dark .secondary-footer .social-link a {
        margin: 0 15px 0 0;
    }
    .f-list {
        margin-bottom: 40px !important;
    }
    .clients.grid-2 li {
        width: 50%;
    }
    .clients.grid-3 li,
    .clients.grid-4 li,
    .clients.grid-5 li,
    .clients.grid-6 li {
        width: 33.33%;
    }
    .clients.angle-box.grid-2 li {
        width: 46%;
    }
    .clients.angle-box.grid-3 li,
    .clients.angle-box.grid-4 li,
    .clients.angle-box.grid-5 li,
    .clients.angle-box.grid-6 li {
        width: 29.33%;
    }
    .feature-bg-box {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 800px) {
    /*portfolio   grid*/
    .portfolio.col-2 .portfolio-item,
    .portfolio.col-3 .portfolio-item,
    .portfolio.col-4 .portfolio-item,
    .portfolio.col-5 .portfolio-item,
    .portfolio.col-6 .portfolio-item {
        /*width: 33.3333333%;*/
        width: 50%;
    }
    .testimonial.grid-2 li,
    .img-post.col-2 .item,
    .img-post.col-3 .item,
    .img-post.col-4 .item {
        width: 100%;
    }
    .subscribe-info,
    .subscribe-form {
        width: 90%;
    }
    .subscribe-info {
        display: inline-block; 
        margin-bottom: 30px;
    }
    .post-parallax .dark-overlay {
        display: none;
    }
    .v-middle {
        padding-bottom: 30px;
    }
    .post-list-aside {
        margin-bottom: 50px;
    }
    .feature-border-box {
        display: inline-block; 
        margin-bottom: 50px;
    }
    .bs-hero.full-screen .carousel-inner .item img {
        width: 100%; 
        height: 100vh;
    }
}

/* -------------------------------
   Tablet Portrait
   ------------------------------- */
@media screen and (max-width: 768px) {
    .breadcrumb {
        display: none;
    }
    .page-title {
        margin-top: 47px;
    }
    .menuzord-responsive.menuzord {
        padding: 0;
    }
    .menuzord-responsive .logo-brand {
        font-size: 20px;
        line-height: 2.55;
    }
    .menuzord-responsive .showhide {
        margin: 0;
    }
    .menuzord-responsive .menuzord-menu {
        float: left !important; 
        width: 100%;
    }
    .menuzord-responsive .menuzord-menu.scrollable {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
    .menuzord-responsive .menuzord-menu.scrollable > .scrollable-fix {
        display: block;
    }
    .menuzord-responsive .menuzord-menu > li {
        position: relative; 
        display: block;
        width: 100%;
    }
    .menuzord-responsive .menuzord-menu > li > a {
        display: block;
        width: 100%;
        padding: 12px 20px;
        border-bottom: solid 1px #f0f0f0;
    }
    .menuzord-responsive .menuzord-menu > li:first-child > a {
        border-top: solid 1px #f0f0f0;
    }
    .menuzord-responsive .menuzord-menu ul.dropdown,
    .menuzord-responsive .menuzord-menu ul.dropdown li ul.dropdown {
        position: static;
        left: 0;
        float: left; 
        width: 100%;
        border: none;
        background: #fff;
    }
    .menuzord-responsive .menuzord-menu ul.dropdown li {
        position: relative;
    }
    .menuzord-responsive .menuzord-menu ul.dropdown li a {
        border-bottom: solid 1px #f0f0f0;
    }
    .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > a {
        padding-left: 40px !important;
    }
    .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li > a {
        padding-left: 60px !important;
    }
    .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li > a {
        padding-left: 80px !important;
    }
    .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li:hover > a {
        /*padding-left: 50px!important*/
    }
    .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li:hover > a {
        padding-left: 70px !important;
    }
    .menuzord-responsive .menuzord-menu.menuzord-indented > li > ul.dropdown > li > ul.dropdown > li > ul.dropdown > li:hover > a {
        padding-left: 90px !important;
    }
    .menuzord-responsive .menuzord-menu li .indicator {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 45px;
        text-align: center;
        border-left: solid 1px #f5f5f5; 
        font-size: 22px;
        line-height: 43px;
    }
    .menuzord-responsive .menuzord-menu li ul.dropdown li .indicator {
        top: 0;
        right: 0;
        height: 38px;
        font-size: 18px;
        line-height: 36px;
    }
    .menuzord-responsive .menuzord-menu > li > .megamenu {
        position: static;
        left: 0 !important;
        width: 100% !important;
        border-top: none;
        border-bottom: solid 1px #f0f0f0;
    }
    .menuzord-responsive .menuzord-menu > li > .megamenu .megamenu-row [class*=col] {
        display: block;
        float: none;
        width: 100% !important;
        margin-top: 15px; 
        margin-left: 0;
    }
    .menuzord-responsive .menuzord-menu > li > .megamenu .megamenu-row,
    .menuzord-responsive .menuzord-menu > li > .megamenu .megamenu-row:first-child [class*=col]:first-child {
        margin-top: 0;
    }
    .menuzord-responsive .menuzord-menu > li.search form {
        width: 100%;
        margin: 4px 0;
        padding: 10px 16px !important;
    }
    .menuzord-responsive .menuzord-menu > li.search form input[type=text],
    .menuzord-responsive .menuzord-tabs-content,
    .menuzord-responsive .menuzord-tabs-nav {
        width: 100%;
    }
    /*-------------------------*/
    /* Mneu items */
    /*-------------------------*/
    .menuzord-responsive .menuzord-menu > li > a {
        padding: 12px 20px !important;
        line-height: 20px;
    }
    /* Dropdown indicators */
    .menuzord-responsive .menuzord-menu li ul.dropdown li .indicator {
        border: none;
    }
    /* Dropdown */
    .menuzord-responsive .menuzord-menu ul.dropdown,
    .menuzord-responsive .menuzord-menu ul.dropdown li ul.dropdown {
        background: #fff;
    }
    /* Dropdown items */
    .menuzord-responsive .menuzord-menu ul.dropdown li a {
        border: none;
    }
    .menuzord-menu li .indicator i.fa-angle-down:before {
        content: "\f107";
    }
    /*----*/
    .top-ads {
        display: none;
    }
    .portfolio-filter li {
        display: inline-block;
        line-height: 40px;
    }
    ul.menuzord-menu {
        background: #fff;
    }
    .login-box.gray-bg {
        margin-bottom: 20px;
    }
    .count-down .c-grid,
    .count-down-alt .c-grid {
        float: left; 
        margin: 10px;
    }
    .count-icon {
        right: 40px;
    }
    .feature-bg-box {
        margin-bottom: 50px !important;
    }
    .catering-form input,
    .catering-form button,
    .catering-form select {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .catering-form .form-group,
    header.nav-center-align .logo-brand img {
        margin: 0;
    }
    header.nav-center-align .logo-brand img {
        height: 40px;
    }
    .cart-info .megamenu-quarter-width {
        width: auto !important;
    }
    .boxed .wrapper,
    .boxed header.sticky,
    .boxed header.sticky .header-sticky,
    .boxed header.transparent-header {
        width: 100%;
    }
    .banner-box h1,
    .banner-title h1 {
        font-size: 30px;
    }
    .banner-box h3,
    .banner-title h3,
    h3 {
        font-size: 18px;
    }
    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 20px;
    }
    .b-lg-txt {
        font-size: 40px !important;
    }
    .post-single .post-desk {
        height: auto;
    }
    .nav-center-align .logo-brand,
    .nav-center-align .menuzord-menu,
    .nav-center-align .menuzord-menu > li {
        float: left;
    }
    /**/
    .travel-form.icon-tabs .nav > li > a {
        padding: 30px 25px;
    }
}

@media screen and (max-width: 767px) {
    .page-title .breadcrumb {
        right: auto; 
        left: 15px;
        margin: 20px 0 0;
    }
    .page-content {
        padding: 50px 0;
    }
    .heading-title {
        margin-bottom: 50px;
    }
    .promo-info {
        padding-right: 0;
    }
    .promo-btn {
        position: relative;
        top: 0;
        right: 0;
        margin-top: 0;
        margin-top: 20px;
    }
    .fun-factor {
        padding: 30px 0;
    }
    .post-grid {
        margin: 0;
    }
    .post-grid .col-lg-1,
    .post-grid .col-lg-10,
    .post-grid .col-lg-11,
    .post-grid .col-lg-12,
    .post-grid .col-lg-2,
    .post-grid .col-lg-3,
    .post-grid .col-lg-4,
    .post-grid .col-lg-5,
    .post-grid .col-lg-6,
    .post-grid .col-lg-7,
    .post-grid .col-lg-8,
    .post-grid .col-lg-9,
    .post-grid .col-md-1,
    .post-grid .col-md-10,
    .post-grid .col-md-11,
    .post-grid .col-md-12,
    .post-grid .col-md-2,
    .post-grid .col-md-3,
    .post-grid .col-md-4,
    .post-grid .col-md-5,
    .post-grid .col-md-6,
    .post-grid .col-md-7,
    .post-grid .col-md-8,
    .post-grid .col-md-9,
    .post-grid .col-sm-1,
    .post-grid .col-sm-10,
    .post-grid .col-sm-11,
    .post-grid .col-sm-12,
    .post-grid .col-sm-2,
    .post-grid .col-sm-3,
    .post-grid .col-sm-4,
    .post-grid .col-sm-5,
    .post-grid .col-sm-6,
    .post-grid .col-sm-7,
    .post-grid .col-sm-8,
    .post-grid .col-sm-9,
    .post-grid .col-xs-1,
    .post-grid .col-xs-10,
    .post-grid .col-xs-11,
    .post-grid .col-xs-12,
    .post-grid .col-xs-2,
    .post-grid .col-xs-3,
    .post-grid .col-xs-4,
    .post-grid .col-xs-5,
    .post-grid .col-xs-6,
    .post-grid .col-xs-7,
    .post-grid .col-xs-8,
    .post-grid .col-xs-9 {
        padding-right: 15px; 
        padding-left: 15px;
    }
    .post-desk.pad-left-less {
        padding-left: 40px !important;
    }
    .portfolio-filter li {
        display: inline-block;
        line-height: 40px;
    }
    .page-title {
        text-align: center;
    }
    .page-title .breadcrumb {
        position: relative !important; 
        top: 0 !important;
        right: 0 !important;
        left: 0 !important;
        margin: 10px 0 0 !important;
    }
    .footer-3 .f-logo-center,
    .footer-4 .f-logo-center {
        left: 40px !important;
    }
    .clients.grid-2 li,
    .clients.grid-3 li,
    .clients.grid-4 li,
    .clients.grid-5 li,
    .clients.grid-6 li {
        width: 50%;
    }
    .clients.angle-box.grid-2 li,
    .clients.angle-box.grid-3 li,
    .clients.angle-box.grid-4 li,
    .clients.angle-box.grid-5 li,
    .clients.angle-box.grid-6 li {
        width: 46%;
    }
    .testimonial.grid-2 li,
    .subscribe-info,
    .subscribe-form,
    .c-info-row .c-info,
    .c-info-row .c-slide,
    .nav > li,
    .featured-box .one-third {
        width: 100%;
    }
    .half-txt {
        width: 80%;
    }
    .icon-box-tabs .nav-pills > li + li {
        margin-left: 0;
    }
    .icon-box-tabs .nav-pills > li {
        margin-bottom: 10px;
        text-align: center;
    }
    .subscribe-info,
    .featured-item {
        display: inline-block;
        width: 100%;
        margin: 5px;
        margin-bottom: 30px;
        padding: 10px;
        border: solid 2px;
        height: auto;
    }
    .subscribe-form input,
    .subscribe-box.text-center .subscribe-form input {
        display: inline-block; 
        margin-bottom: 10px;
    }
    .subscribe-form input,
    .subscribe-form .btn {
        width: 100% !important;
    }
    .error-info {
        display: inline-block;
        width: 100%;
        margin-top: 60px;
        padding-top: 30px;
        padding-left: 0;
        text-align: center; 
        border-top: 1px solid #ddd;
        border-left: none;
    }
    .maintenance-banner img,
    .responsive-img img {
        width: 100%;
        height: auto;
    }
    .feature-border-box {
        display: inline-block; 
        margin-bottom: 50px;
    }
    #portfolio-carousel.owl-theme .owl-controls,
    #portfolio-carousel-alt.owl-theme .owl-controls {
        position: absolute;
        top: auto; 
        right: 0;
        bottom: -48px;
        left: 0;
        margin-top: 10px;
        text-align: center;
    }
    .count-down .c-grid,
    .count-down-alt .c-grid {
        margin: 20px;
    }
    .count-icon {
        right: 40px;
    }
    .p-quantity,
    .featured-box .one-third:nth-child(2) {
        padding: 0;
    }
    .img-post .post-desk {
        padding: 10px;
    }
    .side-object .content {
        width: 100%; 
        padding: 100px 0;
        text-align: center;
    }
    .side-object .object {
        display: none;
    }
    .bs-hero.full-screen .carousel-inner .item img {
        width: 100%; 
        height: 100%;
    }
    .btn {
        margin-bottom: 5px;
    }
    .btn-medium {
        font-size: 12px;
    }
    .intro-title h2 {
        font-size: 20px;
    }
    .intro-title p {
        font-size: 12px;
    }
    .intro-title p span {
        font-size: 20px;
    }
    .tp-caption .btn {
        padding: 5px;
        font-size: 10px;
    }
    /**/
    .travel-form .form-group {
        width: 100%;
    }
    .travel-form.icon-tabs .nav > li > a {
        padding: 10px 5px;
        font-size: 12px;
    }
    .travel-form.icon-tabs li i {
        font-size: 20px;
    }
    .travel-form .nav > li {
        width: auto;
        margin-right: 5px;
    }
    .travel-form .form-control,
    .travel-form .btn {
        height: 30px;
    }
    .travel-form .form-group {
        margin-bottom: 0;
    }
    .travel-form label {
        display: inline-block;
        margin-bottom: 0;
        font-size: 11px; 
        font-weight: normal;
    }
    .travel-form .btn {
        margin-top: 10px;
    }
    .travel-form .btn {
        line-height: 10px;
    }
    .travel-booking + .body-content {
        margin-top: 100px;
    }
    .travel-form {
        position: static;
        margin-top: 20px;
    }
    .travel-form-bg-light {
        padding: 24px 0;
    }
    /**/
    .bs-hero.full-screen .carousel.slide .carousel-control {
        width: 30%;
        margin-top: 0;
        margin-bottom: 0;
    }
    .fashion-title h1 {
        margin-bottom: 0; 
        letter-spacing: 5px;
        font-size: 25px;
        font-weight: 100;
    }
    .bs-hero.full-screen .carousel-inner .item img {
        width: 100%; 
        height: 100vh;
    }
    .fashion-footer .subscribe-form input {
        width: 100% !important;
    }
    .fashion-footer ul {
        display: block; 
        margin: 20px 0 30px 0;
    }
    .fashion-footer ul,
    .fashion-footer .social-link {
        text-align: center;
    }
    .bs-hero.full-screen .carousel-inner .item .bs-info-center {
        width: 100%; 
        margin-left: -50%;
    }
    .bs-hero.full-screen .carousel-inner .item .bs-info-right {
        width: 100%;
    }
    .bs-hero.full-screen .carousel-inner .item .bs-info-right {
        top: 45%;
        right: 0;
        left: 0;
        text-align: center;
    }
}

/* -------------------------------
   Phone
   ------------------------------- */
@media screen and (max-width: 480px) {
    .social-link {
        display: none;
    }

    .header-link {
        display: none;
    }
    /*portfolio   grid*/
    .portfolio.col-2 .portfolio-item,
    .portfolio.col-3 .portfolio-item,
    .portfolio.col-4 .portfolio-item,
    .portfolio.col-5 .portfolio-item,
    .portfolio.col-6 .portfolio-item,
    .testimonial.grid-2 li {
        width: 100%;
        /*width: 50%;*/
    }
    .error-parallax .error-avatar img {
        width: 50%;
        height: auto;
    }
    .error-parallax .error-avatar {
        display: block;
        margin-top: 80px; 
        margin-bottom: 20px;
    }
    .error-parallax .error-info {
        display: inline-block;
        margin-top: 0;
        padding-top: 0; 
        padding-left: 0;
        border-top: none;
        border-left: none;
    }
    /**/
    .coming-soon-logo img {
        width: 30%;
    }
    .count-down .c-grid,
    .count-down-alt .c-grid {
        margin: 10px 30px;
    }
}

/* -------------------------------
   Small Phone
   ------------------------------- */
@media screen and (max-width: 479px) {
    .clients.grid-2 li,
    .clients.grid-3 li,
    .clients.grid-4 li,
    .clients.grid-5 li,
    .clients.grid-6 li,
    .testimonial.grid-2 li {
        width: 100%;
    }
    .clients.angle-box.grid-2 li,
    .clients.angle-box.grid-3 li,
    .clients.angle-box.grid-4 li,
    .clients.angle-box.grid-5 li,
    .clients.angle-box.grid-6 li {
        width: 96%;
    }
    .error-avatar img {
        width: 100%;
        height: auto;
    }
    .error-parallax .error-avatar img {
        width: 50%;
        height: auto;
    }
    .error-parallax .error-avatar {
        display: block;
        margin-top: 80px; 
        margin-bottom: 20px;
    }
    .error-parallax .error-info {
        display: inline-block;
        margin-top: 0;
        padding-top: 0; 
        padding-left: 0;
        border-top: none;
        border-left: none;
    }
    .login-box,
    .login-social-link a,
    .c-info-row .c-info,
    .c-info-row .c-slide {
        width: 100%;
    }
    .login-social-link a {
        display: block; 
        margin-bottom: 15px;
    }
    #portfolio-carousel.owl-theme .owl-controls,
    #portfolio-carousel-alt.owl-theme .owl-controls {
        position: absolute;
        top: auto; 
        right: 0;
        bottom: -48px;
        left: 0;
        margin-top: 10px;
        text-align: center;
    }
    .count-down .c-grid,
    .count-down-alt .c-grid {
        float: left; 
        width: 100%;
        margin: 10px 0;
    }
    .count-icon {
        right: 40px;
    }
    .count-down.circle .c-grid,
    .count-down-alt.circle .c-grid {
        border-radius: 0 !important;
    }
    .count-down.round .c-grid,
    .count-down-alt.round .c-grid {
        border-radius: 0 !important;
    }
    .tp-caption .btn {
        padding: 5px;
        font-size: 6px;
    }
    /**/
    .count-down .c-grid,
    .count-down-alt .c-grid {
        width: 40%; 
        height: 70px;
        padding-top: 5px;
    }
    .count-down .c-value,
    .count-down-alt .c-value {
        font-size: 20px;
    }
    .count-down .c-title,
    .count-down-alt .c-title {
        letter-spacing: 3px; 
        font-size: 12px;
    }
    .count-down .c-grid,
    .count-down-alt .c-grid {
        margin: 10px;
    }
}
