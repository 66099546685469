.breaking:after {
  position: absolute;
  top: 50%;
  left: 98%;
  width: 0;
  height: 0;
  margin-top: -10px;
  content: " ";
  pointer-events: none;
  border: solid;
  border-width: 10px;
  border-color: rgba(51, 51, 51, 0);
  border-left-color: #DAA87C;
}

.slides li,
.carousel.slide .carousel-inner {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
}

.white-popup {
  position: relative;
  background: #FFF;
  padding: 20px;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
}

h1.h4-style {
  margin-bottom: 10px;
  letter-spacing: 1px;
  color: #333;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
}

.h4-style a {
  color: #333;
}

.h4-style a:hover {
  color: #d6b161;
}

@media screen and (max-width: 767px){
  .v-middle {
    height: auto;
  }
  .magazine-latest-news .news-slider {
    margin: 0 0 0 30px;
  }
  .breaking {
    padding: 20px 10px;
  }
  .flex-control-nav {
    display: none;
  }
  .page-content-sm {
    padding: 0;
  }
  .portfolio-filter li, .category-filter li {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .body-content {
    padding-top: 50px;
  }
  .white-popup {
    max-width: 360px;
  }
}